import { Header } from '../components/Header/Header'
import { Footer } from '../components/Footer/Footer'
import { ContactUs } from '../components/ContactUs/ContactUs'


function ContactUsPage() {
  return (
    <div>
      <Header/>
      <ContactUs />
      <Footer />
    </div>
  )
}

export { ContactUsPage }


