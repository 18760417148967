import { SectionTitle, SectionTitleSize, SectionTitleStyle } from '../../components/SectionTitle/SectionTitle'

import styles from './RouteSelection.module.css'

import { ReactComponent as Benchmark } from '../../images/icons/benchmark.svg'
import { ReactComponent as Contractor } from '../../images/icons/contractor.svg'

type RouteSelectionProps = {
  onContractor: () => void
  onBenchmark: () => void
}

function RouteSelection(props: RouteSelectionProps) {
  return (
    <div className={styles.container}>
      <SectionTitle subtitle='WELCOME TO OUR WIZARD' title={'PLEASE SELECT A ROUTE'} size={SectionTitleSize.Small} style={SectionTitleStyle.LightBlue} />
      <div className={styles.buttons}>
        <div
          className={`${styles.button} ${styles.contractor}`}
          onClick={props.onContractor}
        >
          <Contractor/>
          <span>Contractor</span>
        </div>
        <div
          className={`${styles.button} ${styles.benchmark}`}
          onClick={props.onBenchmark}
        >
          <Benchmark/>
          <span>Benchmark</span>
        </div>
      </div>
    </div>
  )
}

export { RouteSelection }
