import { ReactComponent as ButtonArrow } from '../../images/button-arrow.svg'
import { Link } from 'react-router-dom'

import styles from './SectionLink.module.css'

function SectionLink(props: {path: string, label: string, button?: string}) {
  return (
    <Link className={`${styles.container} ${props.button}`} to={props.path}>
      <div className={styles.label}>{props.label}</div>
      <ButtonArrow className={styles.arrow}/>
    </Link>
  )
}

export { SectionLink }
