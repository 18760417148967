import { routes } from '../../routes'
import styles from './Register.module.css'

import { useState } from 'react'
import {
  SectionTitle,
  SectionTitleSize,
  SectionTitleStyle
} from '../SectionTitle/SectionTitle'
import { PasswordStrength } from '../PasswordStrength/PasswordStrength'
import { UserPage } from '../../pages/UserPage/UserPage'
import { BACKEND_URL } from '../../util'
import { Text } from '../Text/Text'
import { Captcha } from '../Captcha/Captcha'
import { Link, useNavigate } from 'react-router-dom'

function Register() {
  const initialData = {
    name: '',
    username: '',
    organisation: '',
    jobTitle: '',
    email: '',
    phone: '',
    password: '',
    confirm: '',
    captcha: ''
  }
  const [state, setState] = useState(initialData)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<
    { field: string | null; message: string } | undefined
  >(undefined)
  const navigate = useNavigate()
  const [grecaptcha, setGrecaptcha] = useState<ReCaptchaV2.ReCaptcha>()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError(undefined)
    setSubmitting(true)

    try {
      const response = await fetch(`${BACKEND_URL}register`, {
        method: 'post',
        body: JSON.stringify(state)
      })
      setSubmitting(false)

      if (response.ok === false) {
        setError({ field: null, message: response.statusText })
        grecaptcha?.reset()
        return
      }

      const json = await response.json()

      if (json.success === false) {
        setError({ field: json.field, message: json.error })
        grecaptcha?.reset()
        return
      }

      navigate(routes.success.path, {
        replace: true,
        state: { permission: json.permission }
      })
    } catch {
      setError({
        field: null,
        message:
          'A network error occurred. Please check your connection and try again.'
      })
      setSubmitting(false)
      grecaptcha?.reset()
      return
    }
  }

  return (
    <UserPage>
      <div className={styles.container}>
        <div className={styles.header}>
          <SectionTitle
            style={SectionTitleStyle.Dark}
            size={SectionTitleSize.Small}
            title={'REGISTER'}
          />
          <div className={styles.login}>
            Already a member?{' '}
            <Link className={styles.link} to={routes.login.path}>
              Login
            </Link>
          </div>
        </div>
        <div className={styles.subtitle}>
          <strong className={styles.strong}>EP Client Members</strong> can register as website users to gain access to our industry leading benchmarking tool, enabling quick and easy access to cost, quality and performance data based benchmarks for our key services.
        </div>
        <div className={styles.subtitle}>
          <strong className={styles.strong}>Appointed Suppliers and Contractors</strong> are registered by EP upon award of a contract in order that KPI can be submitted. You will be advised of your login details upon appointment to a contract.
        </div>
        <div className={styles.subtitle}>
          <strong className={styles.strong}>Potential Suppliers should Not Register on this Website</strong>, but on our tender portal. If your organisation is not registered already, please follow this link to register for potential tender opportunities and then provide a link to <a href="https://in-tendhost.co.uk/epl/aspx/home" target="_blank" className={styles.link}>https://in-tendhost.co.uk/epl/aspx/home</a>.
        </div>
        {error && error.field === null ? <Text>{error.message}</Text> : null}

        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.fields}>
            <div className={styles.field}>
              <label
                className={`${styles.label} ${
                  error && error.field === 'name' ? styles.error : ''
                }`}
                htmlFor={'name'}
              >
                Name
              </label>
              <input
                className={styles.input}
                type={'text'}
                name={'name'}
                onChange={onChange}
                required={true}
                value={state.name}
              />
              {error && error.field === 'name' ? (
                <Text className={styles.error}>{error.message}</Text>
              ) : null}
            </div>
            <div className={styles.field}>
              <label
                className={`${styles.label} ${
                  error && error.field === 'username' ? styles.error : ''
                }`}
                htmlFor={'username'}
              >
                Username
              </label>
              <input
                className={styles.input}
                type={'text'}
                name={'username'}
                onChange={onChange}
                required={true}
                value={state.username}
              />
              {error && error.field === 'username' ? (
                <Text className={styles.error}>{error.message}</Text>
              ) : null}
            </div>
            <div className={styles.field}>
              <label
                className={`${styles.label} ${
                  error && error.field === 'organisation' ? styles.error : ''
                }`}
                htmlFor={'organisation'}
              >
                Organisation Name
              </label>
              <input
                className={styles.input}
                type={'text'}
                name={'organisation'}
                onChange={onChange}
                required={true}
                value={state.organisation}
              />
              {error && error.field === 'organisation' ? (
                <Text className={styles.error}>{error.message}</Text>
              ) : null}
            </div>
            <div className={styles.field}>
              <label
                className={`${styles.label} ${
                  error && error.field === 'jobTitle' ? styles.error : ''
                }`}
                htmlFor={'jobTitle'}
              >
                Job Title
              </label>
              <input
                className={styles.input}
                type={'text'}
                name={'jobTitle'}
                onChange={onChange}
                required={true}
                value={state.jobTitle}
              />
              {error && error.field === 'jobTitle' ? (
                <Text className={styles.error}>{error.message}</Text>
              ) : null}
            </div>

            <div className={styles.field}>
              <label
                className={`${styles.label} ${
                  error && error.field === 'email' ? styles.error : ''
                }`}
                htmlFor={'email'}
              >
                Email
              </label>
              <input
                className={styles.input}
                type={'email'}
                name={'email'}
                onChange={onChange}
                required={true}
                value={state.email}
              />
              {error && error.field === 'email' ? (
                <Text className={styles.error}>{error.message}</Text>
              ) : null}
            </div>
            <div className={styles.field}>
              <label
                className={`${styles.label} ${
                  error && error.field === 'phone' ? styles.error : ''
                }`}
                htmlFor={'phone'}
              >
                Phone
              </label>
              <input
                className={styles.input}
                type={'tel'}
                name={'phone'}
                onChange={onChange}
                required={true}
                value={state.phone}
              />
              {error && error.field === 'phone' ? (
                <Text className={styles.error}>{error.message}</Text>
              ) : null}
            </div>
            <div className={styles.field}>
              <label
                className={`${styles.label} ${
                  error && error.field === 'password' ? styles.error : ''
                }`}
                htmlFor={'password'}
              >
                Password
              </label>
              <input
                className={styles.input}
                type={'password'}
                name={'password'}
                onChange={onChange}
                required={true}
                value={state.password}
                minLength={8}
              />
              {error && error.field === 'password' ? (
                <Text className={styles.error}>{error.message}</Text>
              ) : null}
            </div>
            <div className={styles.field}>
              <label
                className={`${styles.label} ${
                  error && error.field === 'confirm' ? styles.error : ''
                }`}
                htmlFor={'confirm'}
              >
                Password
              </label>
              <input
                className={styles.input}
                type={'password'}
                name={'confirm'}
                onChange={onChange}
                required={true}
                value={state.confirm}
                minLength={8}
              />
              {error && error.field === 'confirm' ? (
                <Text className={styles.error}>{error.message}</Text>
              ) : null}
            </div>
            <div className={styles.field}>
              <PasswordStrength password={state.password} />
            </div>
            <div className={styles.field}>
              <Captcha
                onChange={(token) =>
                  setState((oldData) => ({ ...oldData, captcha: token }))
                }
                setGrecaptcha={setGrecaptcha}
              />
              {error && error.field === 'captcha' ? (
                <Text className={styles.error}>{error.message}</Text>
              ) : null}
            </div>
          </div>
          <button className={styles.submit} disabled={submitting}>
            SUBMIT
          </button>
        </form>
      </div>
    </UserPage>
  )
}

export { Register }
