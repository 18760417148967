import nhf from '../../images/national-housing-federation.png'
import nhf2x from '../../images/national-housing-federation@2x.png'

import styles from './FooterBottom.module.css'

import type { FooterProps } from './Footer'

function FooterBottom(props: Pick<FooterProps, 'companyNumber'>) {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <span className={styles.text}>&copy; {(new Date()).getFullYear()} EASTERN PROCUREMENT</span>
          <span className={styles.divider}></span>
          <span className={styles.text}>CO REG NO {props.companyNumber}</span>
        </div>
        <img className={styles.responsive} src={nhf} srcSet={`${nhf2x} 2x`}/>
      </div>
    </div>
  )
}

export { FooterBottom }
