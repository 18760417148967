import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { UserPage } from 'src/pages/UserPage/UserPage'
import { routes } from 'src/routes'
import { BACKEND_URL, JSONObject } from 'src/util'
import { SectionTitle, SectionTitleSize, SectionTitleStyle } from '../SectionTitle/SectionTitle'
import { Text } from '../Text/Text'
import styles from './VerifyEmail.module.css'

function VerifyEmail() {
  const [tokenError, setTokenError] = useState<{field: string | null, message: string} | undefined>(undefined)
  const [verified, setVerified] = useState(true)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const token = searchParams.get('token')

    if (!token)
      return

    (async () => {
      try {
        const response = await fetch(`${BACKEND_URL}verify-email`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams([['token', token]])
        })

        if (response.ok === false) {
          setTokenError({field: null, message: response.statusText})
          return
        }

        const json: JSONObject = await response.json()
        if (json.success as boolean === false) {
          setTokenError({field: json.field as string | null, message: json.error as string})
          return
        }

        setVerified(true)
      }
      catch (error) {
        setTokenError({field: null, message: (error as Error).message})
        return
      }
    })()
  }, [searchParams])

  return (
    <UserPage>
      <div className={styles.container}>
        <SectionTitle size={SectionTitleSize.Small} style={SectionTitleStyle.Dark} title={'EMAIL VERIFICATION'}/>

        <div className={styles.form_bottom}>
          { verified ?
            <>
              <Text>Your email address has been verified, thank you.</Text>
              <Text>If you were waiting for approval, then you'll be notified when your account has been reviewed.</Text>
              <Text>Oherwise, you can log in now.</Text>
              <Link className={styles.login} to={routes.login.path}>Login now</Link>
            </>
            : tokenError ?
              <Text className={styles.error}>{tokenError.message}</Text>
              :
              <Text>Please wait while we verify your email address.</Text>
          }
        </div>
      </div>
    </UserPage>
  )
}

export { VerifyEmail }


