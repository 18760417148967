import React from 'react'
import { Accordian, PanelProps } from '../Accordian/Accordian'
import { ListItem, OrderedList, UnorderedList } from '../lists/lists'

enum SectionAccordionContentType {
  Bullet = 'bullet',
  Numeric = 'numeric',
  Text = 'text'
}

type SectionAccordion = {
  content: {
    cards: {
      style: SectionAccordionContentType
      title: string
      items: string[]
    }[]
  }
}

const parseContent = (
  content: string[],
  style: SectionAccordionContentType
) => {
  if (style === SectionAccordionContentType.Text) {
    return <pre>{content[0]}</pre>
  } else
    return content.reduce<React.ReactNode[]>((acc, item, idx) => {
      acc.push(<ListItem key={idx}>{item}</ListItem>)
      return acc
    }, [])
}

function SectionAccordion({ content }: SectionAccordion) {
  const panels = content.cards.reduce<PanelProps[]>((acc, card, idx) => {
    switch (card.style) {
      case SectionAccordionContentType.Bullet:
        acc.push({
          title: card.title,
          content: (
            <UnorderedList key={idx}>
              {parseContent(card.items, card.style)}
            </UnorderedList>
          )
        })
        return acc
      case SectionAccordionContentType.Numeric:
        acc.push({
          title: card.title,
          content: (
            <OrderedList key={idx}>
              {parseContent(card.items, card.style)}
            </OrderedList>
          )
        })
        return acc
      case SectionAccordionContentType.Text:
        acc.push({
          title: card.title,
          content: parseContent(card.items, card.style)
        })
        return acc

      default:
        throw new Error('No such Accordion section style: ' + card.style)
    }
  }, [])

  return <Accordian panels={panels} />
}

export { SectionAccordion, SectionAccordionContentType }
