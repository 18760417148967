import styles from './MobileHamburgerMenu.module.css'
import { MenuOutline } from 'react-ionicons'

function MobileHamburgerMenu(props: {onClick: () => void}) {
  return (
    <div className={styles.mobHamburgerMenu} onClick={props.onClick}>
      <MenuOutline
        color={'#00000'}
        height="50px"
        width="50px"
      />
    </div>
  )
}

export { MobileHamburgerMenu }
