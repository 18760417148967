import useFetch from 'use-http'

import { useParams } from 'react-router-dom'
import { BasePage } from 'src/pages/BasePage/BasePage'
import { Block, BlockInstance } from '../Block/Block'
import { PageTitle } from '../PageTitle/PageTitle'
import { API_URL } from 'src/util'


type PostSerialised = {
  _id: string
  title: string
  slug: string
  content: BlockInstance[]
  metatitle?: string
  metadescription?: string
  imageId?: string
  excerpt: string
}


function BlogPost() {
  const { slug } = useParams()

  const options = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const { loading, data } = useFetch(`${API_URL}post/${slug}`, options, [slug])

  if (loading || !data)
    return null

  const post = data as PostSerialised

  return (
    <BasePage>
      <PageTitle title={post.title}/>

      {post.content.map((instance: BlockInstance, index: number) => {
        return <Block
          key={index}
          block={instance}
        />
      })}
    </BasePage>
  )
}

export { BlogPost }
