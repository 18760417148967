import { useCallback } from 'react'
import { CAPTCHA_SITEKEY } from 'src/util'

interface Props {
  onChange: (token: string) => void
  setGrecaptcha: (param: ReCaptchaV2.ReCaptcha) => void
}

export const Captcha = ({ onChange, setGrecaptcha }: Props) => {
  const ref = useCallback((node: HTMLDivElement) => {
    if (node && window.grecaptcha && window.grecaptcha.render && CAPTCHA_SITEKEY) {
      window.grecaptcha.render(node, {
        sitekey: CAPTCHA_SITEKEY,
        callback: (token) => onChange(token),
        'expired-callback': () => onChange(''),
        'error-callback': () => onChange('')
      })
      setGrecaptcha(window.grecaptcha)
    }
  }, [])

  return <div ref={ref} className={'g-recaptcha'} />
}
