import {
  SectionTitle,
  SectionTitleSize,
  SectionTitleStyle
} from '../SectionTitle/SectionTitle'
import { SectionText } from '../Text/Text'
import { CMSSection, CMSSectionBackground } from '../CMSSection/CMSSection'
import { SectionLink } from '../SectionLink/SectionLink'
import { BACKEND_URL } from '../../util'
import { ListItem, OrderedList, UnorderedList } from '../lists/lists'
import type { JSONValue } from '../../util'
import {
  SectionAccordion,
  SectionAccordionContentType
} from '../SectionAccordion/SectionAccordion'
import { Table } from '../Table/Table'
import type { Cell } from '../Table/Table'
import { SectionGuide } from '../SectionGuide/SectionGuide'
import type { GuideCard } from '../SectionGuide/SectionGuide'
import { Hero } from '../Hero/Hero'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { PageTitle } from '../PageTitle/PageTitle'
import { RequestCallback } from '../RequestCallback/RequestCallback'
import { TeamMember, TeamMemberProps } from '../TeamMember/TeamMember'
import { Team } from '../Team/Team'
import { Chairman } from '../Chairman/Chairman'
import { CarouselItem, Carousel } from '../Carousel/Carousel'
import { Link } from '../Link/Link'
import { Partner, Partners } from '../Partners/Partners'

type BlockInstance = {
  name: string
  content: { [field: string]: JSONValue }
}

function Block({ block, background }: { block: BlockInstance, background?: CMSSectionBackground }) {
  switch (block.name) {
    case 'Brands':
      return (
        <Partners
          partners={block.content.brands as Partner[]}
        />
      )
    case 'Hero':
      return (
        <Hero
          title={block.content.title as string}
          subtitle={block.content.subtitle as string}
          content={''}
        />
      )
    case 'Page Title':
      return (
        <PageTitle title={block.content.title as string} subtitle={block.content.subtitle as string}/>
      )
    case 'Section Link':
      return (
        <SectionLink
          path={block.content.link as string}
          label={block.content.label as string}
        />
      )
    case 'Title Link':
      return <Link href={`#${block.content.reference}`}>{block.content.label}</Link>
    case 'Section Title':
      return (
        <SectionTitle
          title={block.content.title as string}
          subtitle={block.content.subtitle as string}
          reference={block.content.reference as string}
          size={(block.content.size ?? 'medium') as SectionTitleSize}
          style={background === CMSSectionBackground.Blue ? SectionTitleStyle.Light : SectionTitleStyle.Dark}
        />
      )
    case 'Textarea':
      return <SectionText style={{...(background === CMSSectionBackground.Blue ? {color: '#fff'} : {})}}>{block.content.text}</SectionText>
    case 'Image':
      return <img src={`${BACKEND_URL}file/${block.content.image}`} />
    case 'File':
      return <Link href={`${BACKEND_URL}file/${block.content.fileId}`}>{block.content.label}</Link>
    case 'External Link':
      return <Link href={block.content.link as string} target={'_blank'}>{block.content.label}</Link>
    case 'List': {
      const List =
        block.content.style === 'bullet' ? UnorderedList : OrderedList
      return (
        <List>
          {(block.content.items as string[]).map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </List>
      )
    }
    case 'Accordion': {
      return (
        <SectionAccordion
          content={
            block.content as {
              cards: {
                style: SectionAccordionContentType
                title: string
                items: string[]
              }[]
            }
          }
        />
      )
    }
    case 'Carousel': {
      return (
        <Carousel items={block.content.cards as CarouselItem[]}/>
      )
    }
    case 'Table': {
      return <Table table={block.content.table as unknown as Cell[][]} />
    }
    case 'Guide': {
      return (
        <SectionGuide cards={block.content.cards as unknown as GuideCard[]} />
      )
    }
    case 'Breadcrumb': {
      return (
        <Breadcrumb/>
      )
    }
    case 'Section': {
      const background =
        block.content.background === 'blue'
          ? CMSSectionBackground.Blue
          : block.content.background === 'white'
            ? CMSSectionBackground.White
            : CMSSectionBackground.Grey

      return (
        <CMSSection
          background={background}
          primary={((block.content.primary ?? []) as BlockInstance[]).map((subBlock, index) => {
            return <Block key={`${index}_${subBlock.name}`} block={subBlock} background={background}/>
          })}
          secondary={((block.content.secondary ?? []) as BlockInstance[]).map((subBlock, index) => {
            return <Block key={`${index}_${subBlock.name}`} block={subBlock} background={background}/>
          })}
          reversed={block.content.reversed as boolean ?? false}
        />
      )
    }
    case 'Request Callback':
      return (
        <RequestCallback/>
      )
    case 'Team':
      return (
        <Team>
          {(block.content.team as TeamMemberProps[]).map(member => {
            return (
              <TeamMember
                name={member.name}
                position={member.position}
                photoId={`${BACKEND_URL}file/${member.photoId}`}
              />
            )
          })}
        </Team>
      )
    case 'Featured Person':
      return (
        <Chairman
          image={`${BACKEND_URL}file/${block.content.photo}`}
          name={block.content.name as string}
          position={block.content.position as string}
        />
      )
    default:
      throw new Error('No such Section BlockInstance: ' + block.name)
  }
}

export { Block }
export type { BlockInstance }
