import { useEffect, useState } from 'react'
import {
  SectionTitle,
  SectionTitleSize,
  SectionTitleStyle
} from '../SectionTitle/SectionTitle'

import { ContractorsResult } from '../ContractorsResult/ContractorsResult'
import { Select } from '../Select/Select'
import { NumberField } from '../NumberField/NumberField'

import styles from './MultiForm.module.css'
import { RouteSelection } from '../../wizard/RouteSelection/RouteSelection'
import { InputField } from '../InputField/InputField'
import { Text } from '../Text/Text'
import useFetch from 'use-http'
import { API_URL } from '../../util'
import { useAppContext } from '../../Context'
import { Link } from 'react-router-dom'
import { routes } from '../../routes'
import { Button } from 'src/wizard/Button/Button'
import { Captcha } from '../Captcha/Captcha'
import { Notification } from '../Notification/Notification'

type WizardRoute = 'contractor' | 'benchmark'
type WizardData = {
  route: WizardRoute | null
  contractor: string | null
  service: string | null
  specification: { [field: string]: string }
  details: {
    budget: number | ''
    quantity: number | ''
    location: string
  }
  historical: {
    cost: number | ''
    procurement: string | null
    framework: string | null
    contractor: string | null
    performance: number | null
  }
}

enum Steps {
  Route,
  Contractor, // contractor only
  Service,
  Specification, // service only
  Details,
  Historical,
  Results
}

function nextStep(step: Steps, route: WizardRoute) {
  switch (step) {
    case Steps.Route:
      return route === 'contractor' ? Steps.Contractor : Steps.Service
    case Steps.Contractor:
      return Steps.Service
    case Steps.Service:
      return Steps.Specification
    case Steps.Specification:
      return Steps.Details
    case Steps.Details:
      return Steps.Historical
    case Steps.Historical:
      return Steps.Results
  }
  // should never happen, but if in doubt just go back to the start
  return Steps.Route
}

function prevStep(step: Steps, route: WizardRoute) {
  switch (step) {
    case Steps.Contractor:
      return Steps.Route
    case Steps.Service:
      return route === 'contractor' ? Steps.Contractor : Steps.Route
    case Steps.Specification:
      return Steps.Service
    case Steps.Details:
      return Steps.Specification
    case Steps.Historical:
      return Steps.Details
    case Steps.Results:
      return Steps.Historical
  }
  // should never happen, but if in doubt just go back to the start
  return Steps.Route
}

type FormProps = {
  step: Steps
  other: () => void
  setCaptchaToken: React.Dispatch<React.SetStateAction<string>>
  setGrecaptcha: React.Dispatch<
    React.SetStateAction<ReCaptchaV2.ReCaptcha | undefined>
  >
}

type Contractor = {
  _id: string
  name: string
}

function FormSteps(props: FormProps) {
  const context = useAppContext()
  const { wizard: form, setContext } = context
  const setForm = (s: WizardData) => setContext({ ...context, wizard: s })

  const options = { headers: { 'Content-Type': 'application/json' } }
  const { data: contractors } = useFetch(`${API_URL}contractors`, options, [])
  const { data: frameworks } = useFetch(`${API_URL}frameworks`, options, [])
  const { data: choices } = useFetch(`${API_URL}choices`, options, [])

  const contractor =
    form.contractor && contractors
      ? contractors.find((c: Contractor) => c._id === form.contractor)
      : null

  const onHistoricalChange = (field: string, value: string | number) => {
    setForm({ ...form, historical: { ...form.historical, [field]: value } })
  }

  if (props.step === Steps.Contractor)
    return (
      <div className={styles.fullWidth}>
        <div className={styles.grid}>
          <SectionTitle
            title={'SELECT CONTRACTOR'}
            size={SectionTitleSize.Small}
            style={SectionTitleStyle.LightBlue}
          />
          <Select
            options={[
              { value: '', label: '', disabled: true },
              ...((contractors as Contractor[]) ?? [])
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((c) => ({ value: c._id, label: c.name }))
            ]}
            value={form.contractor ?? ''}
            onChange={(e) => setForm({ ...form, contractor: e.target.value })}
            required={true}
          />
        </div>
      </div>
    )

  if (props.step === Steps.Service)
    return (
      <div className={styles.fullWidth}>
        <div className={styles.grid}>
          <SectionTitle
            title={'SELECT SERVICE'}
            size={SectionTitleSize.Small}
            style={SectionTitleStyle.LightBlue}
          />
          <Select
            options={[
              { value: '', label: '', disabled: true },
              ...(frameworks ?? [])
                .filter((f: { _id: string }) => {
                  if (form.route === 'benchmark') return true
                  return contractor?.frameworks.find(
                    (cf: { contractorFramework?: { frameworkId: string } }) =>
                      cf.contractorFramework?.frameworkId === f._id
                  )
                })
                .map((f: { _id: string; name: string }) => ({
                  value: f._id,
                  label: f.name
                }))
            ]}
            value={form.service ?? ''}
            onChange={(e) => setForm({ ...form, service: e.target.value })}
            required={true}
          />
          {form.route === 'contractor' ? (
            <Text>
              Can't find the service you're looking for?{' '}
              <span onClick={props.other} className={styles.look}>
                Look for all contractors.
              </span>
            </Text>
          ) : null}
        </div>
      </div>
    )

  if (props.step === Steps.Specification) {
    const questions = choices.filter(
      (c: { frameworkId: string; isCore: boolean }) =>
        c.frameworkId === form.service && !c.isCore
    )
    return (
      <div className={styles.fullWidth}>
        <div className={styles.grid}>
          <SectionTitle
            title={'SELECT SPECIFICATION AND OPTIONS'}
            size={SectionTitleSize.Small}
            style={SectionTitleStyle.LightBlue}
          />
          {questions.map(
            (q: { _id: string; name: string; options: { name: string }[] }) => {
              return (
                <label key={q._id}>
                  <Text>{q.name}</Text>
                  <Select
                    options={[
                      { value: '', label: '', disabled: true },
                      ...q.options.map((o: { name: string }) => ({
                        value: o.name,
                        label: o.name
                      }))
                    ]}
                    value={form.specification[q._id] ?? ''}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        specification: {
                          ...form.specification,
                          [q._id]: e.target.value
                        }
                      })
                    }
                    required={true}
                  />
                </label>
              )
            }
          )}
        </div>
      </div>
    )
  }

  if (props.step === Steps.Details)
    return (
      <div className={styles.fullWidth}>
        <div className={styles.grid}>
          <SectionTitle
            title={'ENTER PROJECT DETAILS'}
            size={SectionTitleSize.Small}
            style={SectionTitleStyle.LightBlue}
          />
          <label>
            <Text>Project Budget</Text>
            <NumberField
              min={1}
              placeholder={'1'}
              value={form.details.budget}
              onChange={(event) =>
                setForm({
                  ...form,
                  details: {
                    ...form.details,
                    budget: event.target.value ? Number(event.target.value) : ''
                  }
                })
              }
              step={1}
              required={true}
            />
          </label>
          <label>
            <Text>Quantity</Text>
            <NumberField
              value={form.details.quantity}
              onChange={(event) =>
                setForm({
                  ...form,
                  details: {
                    ...form.details,
                    quantity: event.target.value
                      ? Number(event.target.value)
                      : ''
                  }
                })
              }
              min={1}
              step={1}
              required={true}
            />
          </label>
          <label>
            <Text>Location</Text>
            <Select
              options={[
                { value: '', label: '', disabled: true },
                { value: 'Babergh', label: 'Babergh' },
                { value: 'Basildon', label: 'Basildon' },
                { value: 'Boston', label: 'Boston' },
                { value: 'Braintree', label: 'Braintree' },
                { value: 'Breckland', label: 'Breckland' },
                { value: 'Brentwood', label: 'Brentwood' },
                { value: 'Broadland', label: 'Broadland' },
                { value: 'Broxbourne', label: 'Broxbourne' },
                { value: 'Cambridge', label: 'Cambridge' },
                { value: 'Cambridgeshire', label: 'Cambridgeshire' },
                { value: 'Castle Point', label: 'Castle Point' },
                { value: 'Chelmsford', label: 'Chelmsford' },
                { value: 'Colchester', label: 'Colchester' },
                { value: 'Dacorum', label: 'Dacorum' },
                { value: 'East Cambridgeshire', label: 'East Cambridgeshire' },
                { value: 'East Hertfordshire', label: 'East Hertfordshire' },
                { value: 'East Lindsey', label: 'East Lindsey' },
                { value: 'East Suffolk', label: 'East Suffolk' },
                { value: 'Epping Forest', label: 'Epping Forest' },
                { value: 'Essex', label: 'Essex' },
                { value: 'Fenland', label: 'Fenland' },
                { value: 'Great Yarmouth', label: 'Great Yarmouth' },
                { value: 'Harlow', label: 'Harlow' },
                { value: 'Hertfordshire', label: 'Hertfordshire' },
                { value: 'Hertsmere', label: 'Hertsmere' },
                { value: 'Huntingdonshire', label: 'Huntingdonshire' },
                { value: 'Ipswich', label: 'Ipswich' },
                {
                  value: "King's Lynn and West Norfolk",
                  label: "King's Lynn and West Norfolk"
                },
                { value: 'Lincoln', label: 'Lincoln' },
                { value: 'Lincolnshire', label: 'Lincolnshire' },
                { value: 'Maldon', label: 'Maldon' },
                { value: 'Mid Suffolk', label: 'Mid Suffolk' },
                { value: 'Norfolk', label: 'Norfolk' },
                {
                  value: 'North East Lincolnshire',
                  label: 'North East Lincolnshire'
                },
                { value: 'North Hertfordshire', label: 'North Hertfordshire' },
                { value: 'North Kesteven', label: 'North Kesteven' },
                { value: 'North Lincolnshire', label: 'North Lincolnshire' },
                { value: 'North Norfolk', label: 'North Norfolk' },
                { value: 'Norwich', label: 'Norwich' },
                { value: 'Rochford', label: 'Rochford' },
                {
                  value: 'South Cambridgeshire',
                  label: 'South Cambridgeshire'
                },
                { value: 'South Holland', label: 'South Holland' },
                { value: 'South Kesteven', label: 'South Kesteven' },
                { value: 'South Norfolk', label: 'South Norfolk' },
                { value: 'St Albans', label: 'St Albans' },
                { value: 'Stevenage', label: 'Stevenage' },
                { value: 'Suffolk', label: 'Suffolk' },
                { value: 'Tendring', label: 'Tendring' },
                { value: 'Three Rivers', label: 'Three Rivers' },
                { value: 'Uttlesford', label: 'Uttlesford' },
                { value: 'Watford', label: 'Watford' },
                { value: 'Welwyn Hatfield', label: 'Welwyn Hatfield' },
                { value: 'West Lindsey', label: 'West Lindsey' },
                { value: 'West Suffolk', label: 'West Suffolk' },
                { value: 'Other', label: 'Other' }
              ]}
              value={form.details.location}
              onChange={(event) =>
                setForm({
                  ...form,
                  details: { ...form.details, location: event.target.value }
                })
              }
              required={true}
            />
          </label>
        </div>
      </div>
    )

  if (props.step === Steps.Historical)
    return (
      <div className={styles.fullWidth}>
        <div className={styles.grid}>
          <SectionTitle
            title={'ENTER HISTORICAL DETAILS'}
            size={SectionTitleSize.Small}
            style={SectionTitleStyle.LightBlue}
          />
          <label>
            <Text>Average cost per item including VAT</Text>
            <NumberField
              value={form.historical.cost}
              step={1}
              min={0}
              onChange={(event) =>
                onHistoricalChange(
                  'cost',
                  event.target.value ? Number(event.target.value) : ''
                )
              }
            />
          </label>
          <label>
            <Text>How did you procure?</Text>
            <Select
              options={[
                { value: '', label: '', disabled: true },
                { value: 'framework', label: 'Framework' },
                { value: 'self', label: 'Self procured' },
                { value: 'negotiated', label: 'Negotiated' }
              ]}
              value={form.historical.procurement ?? ''}
              onChange={(event) =>
                onHistoricalChange('procurement', event.target.value)
              }
            />
          </label>
          {form.historical.procurement === 'framework' ? (
            <>
              <label>
                <Text>Which framework did you use?</Text>
                <InputField
                  type={'text'}
                  value={form.historical.framework ?? ''}
                  onChange={(event) =>
                    onHistoricalChange('framework', event.target.value)
                  }
                  required={true}
                />
              </label>
              <label>
                <Text>Who was the contractor?</Text>
                <InputField
                  type={'text'}
                  value={form.historical.contractor ?? ''}
                  onChange={(event) =>
                    onHistoricalChange('contractor', event.target.value)
                  }
                  required={true}
                />
              </label>
              <label>
                <Text>Rate their performance</Text>
                <Select
                  options={[
                    { value: '-1', label: '', disabled: true },
                    { value: '1', label: '1 (Poor)' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10 (Excellent)' }
                  ]}
                  value={(form.historical.performance ?? -1).toString()}
                  onChange={(event) =>
                    onHistoricalChange(
                      'performance',
                      Number(event.target.value)
                    )
                  }
                  required={true}
                />
              </label>
            </>
          ) : null}
          <Captcha
            onChange={(token) => props.setCaptchaToken(token)}
            setGrecaptcha={props.setGrecaptcha}
          />
        </div>
      </div>
    )

  return null
}

const MultiForm = () => {
  const [step, setStep] = useState(Steps.Route)
  const [error, setError] = useState<{
    success: boolean
    message: string
  } | null>(null)
  const [captchaToken, setCaptchaToken] = useState('')
  const [grecaptcha, setGrecaptcha] = useState<ReCaptchaV2.ReCaptcha>()

  const [submission, setSubmission] = useState<string | null>(null)

  const context = useAppContext()

  const next = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const form = document.querySelector('form')
    for (const field of (form as HTMLFormElement).elements) {
      if ((field as HTMLInputElement).reportValidity() === false) return
    }

    setStep(nextStep(step, context.wizard.route as WizardRoute))
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const prev = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setError(null)

    setStep(prevStep(step, context.wizard.route as WizardRoute))
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    // if we're on the wrong step then nothing more to do
    if (step !== Steps.Results) return

    // clear out any results
    context.setContext({ ...context, results: [] })

    // make the form submission
    ;(async function () {
      if (context.user === null) return
      setError(null)

      const data = new URLSearchParams()
      data.append('username', context.user.username as string)
      data.append('form', JSON.stringify(context.wizard))
      data.append('captcha', captchaToken)

      const response = await fetch(`${API_URL}wizard/submit`, {
        method: 'post',
        headers: {
          Authorization: `Token ${context.user.token}`
        },
        body: data
      })

      grecaptcha?.reset()

      if (!response.ok) {
        console.error(response.status)
        return
      }

      const json = await response.json()

      if (!json.success) {
        setError({
          success: false,
          message: json.message + ' Please go back and try again.'
        })
        return
      }

      context.setContext({ ...context, results: json.results })
      setSubmission(json.submission)
    })()
  }, [context.wizard, step])

  if (
    context.user === null ||
    context.user?.permission === 'yJsiaQY8KbYNpYH5Q'
  ) {
    return (
      <div className={styles.pageWrapper}>
        <div className={styles.innerPageWrapper}>
          <div className={styles.container}>
            <div className={styles.accessMessage}>
              <div style={{ fontSize: '20px', fontWeight: '500' }}>
                {context.user
                  ? 'Permission denied.'
                  : 'You need to be logged in to use benchmark and commission wizard.'}
              </div>
              {context.user === null ? (
                <div>
                  <Link className={styles.login} to={routes.login.path}>
                    Login
                  </Link>{' '}
                  or{' '}
                  <Link className={styles.register} to={routes.register.path}>
                    Register
                  </Link>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const other = () => {
    context.setContext({
      ...context,
      wizard: { ...context.wizard, route: 'benchmark', contractor: null }
    })
    setStep(nextStep(Steps.Route, 'benchmark'))
  }

  const notification = error ? <Notification response={error} /> : ''

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.innerPageWrapper}>
        <div className={styles.container}>
          <form>
            <div className={styles.row}>
              {step === Steps.Results ? (
                <ContractorsResult
                  results={context.results}
                  submission={submission}
                  other={other}
                />
              ) : step === Steps.Route ? (
                <RouteSelection
                  onContractor={() => {
                    context.setContext({
                      ...context,
                      wizard: { ...context.wizard, route: 'contractor' }
                    })
                    setStep(nextStep(step, 'contractor'))
                  }}
                  onBenchmark={() => {
                    context.setContext({
                      ...context,
                      wizard: { ...context.wizard, route: 'benchmark' }
                    })
                    setStep(nextStep(step, 'benchmark'))
                  }}
                />
              ) : (
                <FormSteps
                  step={step}
                  other={other}
                  setGrecaptcha={setGrecaptcha}
                  setCaptchaToken={setCaptchaToken}
                />
              )}
            </div>
            {notification}
            <div className={styles.rowPrevNext}>
              <Button
                colour={'green'}
                disabled={step === Steps.Route}
                onClick={prev}
                label={'Back'}
              />
              {step !== Steps.Route && step !== Steps.Results ? (
                <Button
                  colour={'blue'}
                  onClick={next}
                  label={'Save & Continue'}
                />
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export { MultiForm }
export type { WizardRoute, WizardData }
