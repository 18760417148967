import React from 'react'

import styles from './Link.module.css'

type LinkProps = {
  href: string
  target?: React.HTMLAttributeAnchorTarget
  children: React.ReactNode
}

function Link(props: LinkProps) {
  const rel = props.target === '_blank' ? 'noopener noreferrer' : undefined

  return <a href={props.href} target={props.target} rel={rel} className={styles.link}>{props.children}</a>
}

export { Link }
