import styles from './lists.module.css'

function ListItem(props: {children: React.ReactNode}) {
  return <li className={styles.item}>{props.children}</li>
}

function OrderedList(props: {children: React.ReactNode}) {
  return <ol className={`${styles.list} ${styles.ordered}`}>{props.children}</ol>
}

function UnorderedList(props: {children: React.ReactNode}) {
  return <ul className={`${styles.list} ${styles.unordered}`}>{props.children}</ul>
}

export { ListItem, OrderedList, UnorderedList }
