import styles from './Table.module.css'
import { ReactComponent as Checkmark } from '../../images/icons/ionic-ios-checkmark-circle.svg'

enum TableContentType {
  Text = 'text',
  Checkbox = 'checkbox'
}

interface Cell {
  style?: TableContentType
  value: string | boolean
}

type Props = {
  table: Cell[][]
}

function Table({ table }: Props) {
  const getCellContent = (cell: Cell, style: TableContentType) => {
    if (style === TableContentType.Checkbox && cell.value === true) {
      return <Checkmark className={styles.check_icon} />
    } else if (
      style === TableContentType.Text &&
      typeof cell.value === 'string' &&
      cell.value.length > 0
    )
      return cell.value
    else return <>&nbsp;</>
  }

  const renderTableContent = table.map((row, rIdx) => {
    if (rIdx === 0) {
      return (
        <thead key={rIdx}>
          <tr className={styles.tr_blue}>
            {row.map((col, cIdx) => (
              <td className={styles.tableTitle} key={cIdx}>
                {col.value}
              </td>
            ))}
          </tr>
        </thead>
      )
    } else {
      return (
        <tbody key={rIdx}>
          <tr className={rIdx % 2 === 0 ? '' : styles.tr_grey}>
            {row.map((col, cIdx) => (
              <td key={cIdx}>
                {getCellContent(col, table[0][cIdx].style as TableContentType)}
              </td>
            ))}
          </tr>
        </tbody>
      )
    }
  })
  return (
    <>
      <div className={styles.table_wrapper}>
        <div className={styles.table_wrapperInner}>
          <table className={styles.width_100}>{renderTableContent}</table>
        </div>
      </div>
    </>
  )
}

export { Table, TableContentType }
export type { Props as TableProps, Cell }
