import styles from './PageTitle.module.css'

type PageTitle = {
  title: string
  subtitle?: string
}
function PageTitle(props: PageTitle) {
  return (
    <div className={styles.background}>
      <div className={styles.overlay}>
        <div className={styles.container}>
          <div className={`${styles.title}`}>{props.title}</div>
          {props.subtitle ?
            <div className={`${styles.subtitle}`}>{props.subtitle}</div>
            :
            null
          }
        </div>
      </div>
    </div>
  )
}
export { PageTitle }
