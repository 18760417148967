import React from 'react'
import styles from './Select.module.css'

type SelectProps = {
  options: {value: string | number, label: string, disabled?: boolean}[]
  value: string | number | undefined
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  required?: boolean
  sort?: boolean
}

function Select(props: SelectProps) {
  const { sort = true } = props

  const options = sort ? props.options.sort((a, b) => a.label.localeCompare(b.label)) : props.options

  return (
    <select
      className={styles.select}
      value={props.value}
      onChange={props.onChange}
      required={props.required}
    >
      {options.map(opt => {
        return (
          <option
            key={opt.value}
            value={opt.value}
            disabled={opt.disabled}
            hidden={opt.disabled}
          >{opt.label}</option>
        )
      })}
    </select>
  )
}

export { Select }
