
import styles from './Button.module.css'

type ButtonProps = {
  name?: string
  label: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  colour?: 'blue' | 'green'
  formNoValidate?: boolean
  submit?: boolean
}

function Button(props: ButtonProps) {
  return (
    <button

      name={props.name}
      type={props.submit ? 'submit' : 'button'}
      formNoValidate={props.formNoValidate ?? false}
      className={`${styles.button} ${styles[props.colour ?? 'grey']}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >{props.label}</button>
  )
}

export { Button }
