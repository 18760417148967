import React, { useState } from 'react'
import { BACKEND_URL } from 'src/util'
import { Captcha } from '../Captcha/Captcha'
import { InputField } from '../InputField/InputField'
import { Notification } from '../Notification/Notification'
import { Select } from '../Select/Select'
import { TextArea } from '../TextArea/TextArea'
import styles from './RequestCallback.module.css'


function RequestCallback() {
  const initialData = {
    name: '',
    email: '',
    telephone: '',
    time: '',
    subject: '',
    message: ''
  }
  const [data, setData] = useState<{
    name: string
    email: string
    telephone: string
    time: string
    subject: string
    message: string
  }>(initialData)
  const [grecaptcha, setGrecaptcha] = useState<ReCaptchaV2.ReCaptcha>()

  const [response, setResponse] = useState<{
    success: boolean
    message: string
  } | null>(null)

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    fetch(`${BACKEND_URL}callback`, {
      method: 'post',
      body: new URLSearchParams(data)
    })
      .then((res) => {
        console.log(res)
        return res.text()
      })
      .then((result) => {
        const res = JSON.parse(result)
        setResponse(res)
        if (res.success) setData(initialData)
      })
      .catch(() => {
        setResponse({
          success: false,
          message: 'Something went wrong. Please try again later.'
        })
      })
      .finally(() => grecaptcha?.reset())
  }

  const notification = response ? <Notification response={response} /> : ''
  const times = [
    {value: '', label: 'Best time to call you', disabled: true},
    {value: 'morning', label: 'Morning'},
    {value: 'afternoon', label: 'Afternoon'},
    {value: 'evening', label: 'Evening'},
    {value: 'any', label: 'No preference'},
  ]

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.overlay}>
        <div className={styles.container}>
          <div id={'callback'} className={styles.formWrapper}>
            <div className={styles.formContainer}>
              <div className={styles.title}>REQUEST A CALL BACK / VC</div>
              <form
                method={'post'}
                className={styles.form}
                onSubmit={onSubmit}
              >
                {notification}
                <div className={styles.columns}>
                  <div>
                    <InputField
                      type={'text'}
                      placeholder={'Your name'}
                      value={data.name}
                      required={true}
                      onChange={e => setData({ ...data, name: e.target.value })}
                    />
                    <InputField
                      type={'email'}
                      placeholder={'Your email'}
                      value={data.email}
                      required={true}
                      onChange={e => setData({ ...data, email: e.target.value })}
                    />
                    <InputField
                      type={'tel'}
                      placeholder={'Your phone number'}
                      required={true}
                      value={data.telephone}
                      onChange={e => setData({ ...data, telephone: e.target.value })}
                    />
                    <Select
                      value={data.time}
                      onChange={e => setData({ ...data, time: e.target.value })}
                      required={true}
                      options={times}
                      sort={false}
                    />
                  </div>
                  <div>
                    <Select
                      value={data.subject}
                      onChange={e => setData({...data, subject: e.target.value})}
                      required={true}
                      options={[
                        {value: '', label: 'Subject', disabled: true},
                        {value: 'Complaint', label: 'Performance Issue / Complaint'},
                        {value: 'New Business', label: 'New Business / Benchmark'},
                        {value: 'Other', label: 'Other'}
                      ]}
                      sort={false}
                    />
                    <TextArea
                      value={data.message}
                      placeholder={'Your message'}
                      className={styles.message}
                      onChange={event => {
                        setData(oldData => ({...oldData, message: event.target.value}))
                      }}
                    />
                  </div>
                  <div className={styles.captcha}>
                    <Captcha
                      onChange={(token) =>
                        setData((oldData) => ({ ...oldData, captcha: token }))
                      }
                      setGrecaptcha={setGrecaptcha}
                    />
                  </div>
                  <button className={styles.submit} type={'submit'}>
                    REQUEST A CALL BACK
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { RequestCallback }
