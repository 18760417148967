import styles from './SectionTitle.module.css'

enum SectionTitleSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

enum SectionTitleStyle {
  Dark,
  Light,
  LightBlue
}

type SectionTitle = {
  title: string
  subtitle?: string
  reference?: string
  size: SectionTitleSize
  style: SectionTitleStyle
}

function SectionTitle(props: SectionTitle) {
  let size: string
  switch (props.size)
  {
    case SectionTitleSize.Small:
      size = styles.small
      break
    case SectionTitleSize.Medium:
      size = styles.medium
      break
    case SectionTitleSize.Large:
      size = styles.large
      break
  }

  // For text color 
  let style: string
  switch (props.style)
  {
    case SectionTitleStyle.Dark:
      style = styles.dark
      break
    case SectionTitleStyle.Light:
      style = styles.white
      break
    case SectionTitleStyle.LightBlue:
      style = styles.lightBlue
      break
  }

  return (
    <div className={styles.heading}>
      {props.reference ?
        <div className={styles.reference} id={props.reference}></div>
        :
        null
      }
      {props.subtitle ?
        <div className={`${styles.subtitle} ${style}`}>{props.subtitle}</div>
        :
        null
      }
      <div className={`${styles.title} ${size} ${style}`}>{props.title}</div>
    </div>
  )
}

export { SectionTitle, SectionTitleSize, SectionTitleStyle }
