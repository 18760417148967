import styles from './TextArea.module.css'

type TextAreaProps = {
  value: string
  placeholder?: string
  className?: string
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  required?: boolean
}

function TextArea(props: TextAreaProps) {
  return (
    <textarea
      className={`${styles.textarea} ${props.className}`}
      onChange={props.onChange}
      value={props.value}
      placeholder={props.placeholder}
      required={props.required ?? false}
    />
  )
}

export { TextArea }
