import { useState } from 'react'

import styles from './Accordian.module.css'

import { ReactComponent as ButtonArrow } from '../../images/button-arrow.svg'

type PanelProps = {
  title: string
  content: React.ReactNode
}

type PanelPropsInternal = PanelProps & {
  active: string | null
  onClick: React.Dispatch<React.SetStateAction<string | null>>
}

type AccordianProps = {
  panels: PanelProps[]
}

function Panel(props: PanelPropsInternal) {
  const active = props.active === props.title

  const click = () => {
    if (props.active === null)
    {
      props.onClick(props.title)
    }
    else
    {
      props.onClick(null)

      if (!active)
        setTimeout(() => props.onClick(props.title), 500)
    }
  }

  return (
    <div className={styles.panel}>
      <div className={styles.top} onClick={click}>
        <span className={`${styles.title} ${active ? styles.activeTitle : ''}`}>{props.title}</span>
        <ButtonArrow className={`${styles.arrow} ${active ? styles.activeArrow : ''}`}/>
      </div>
      <div className={`${styles.content} ${active ? styles.activeContent : ''}`}>
        <div className={styles.text}>
          {props.content}
        </div>
      </div>
    </div>
  )
}

function Accordian(props: AccordianProps) {
  const [active, setActive] = useState<string | null>(null)

  return (
    <div className={styles.container}>
      {props.panels.map(panel => <Panel key={panel.title} {...panel} active={active} onClick={setActive}/>)}
    </div>
  )
}

export { Accordian }
export type { PanelProps, AccordianProps }
