import styles from './TeamMember.module.css'

type TeamMemberProps = {
  name: string
  position: string
  photoId: string
}

function TeamMember(props: TeamMemberProps) {
  return (
    <div className={styles.container}>
      <div className={styles.photo}>
        <img className={styles.image} src={props.photoId} alt={props.name} />
      </div>
      <div className={styles.label}>
        <span className={styles.name}>{props.name}</span>
        <span className={styles.position}>{props.position}</span>
      </div>
    </div>
  )
}

export { TeamMember }
export type { TeamMemberProps }
