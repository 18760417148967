import { useAppContext } from 'src/Context'
import { BasePage } from 'src/pages/BasePage/BasePage'
import { formatCurrency, formatDate, formatNumber, toLocaleTitleCase } from 'src/util'
import type { WizardRoute } from '../MultiForm/MultiForm'
import { SectionTitle, SectionTitleSize, SectionTitleStyle } from '../SectionTitle/SectionTitle'
import useFetch from 'use-http'
import { API_URL } from '../../util'
import styles from './WizardChosenOptionsAndResults.module.css'
import { Button } from '../../wizard/Button/Button'
import { useNavigate } from 'react-router-dom'
import { routes } from 'src/routes'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { RequestCallback } from '../RequestCallback/RequestCallback'

type WizardOptionProps = {
  label: string
  value: string
}

function WizardOption(props: WizardOptionProps) {
  return (
    <div>
      <div className={styles.optionLabel}>{props.label}</div>
      <div className={styles.optionValue}>{props.value}</div>
    </div>
  )
}

type WizardHeadingProps = {
  heading: string
}

function WizardHeading(props: WizardHeadingProps) {
  return (
    <div className={styles.heading}>{props.heading}</div>
  )
}

function WizardChosenResults() {
  const navigate = useNavigate()
  const context = useAppContext()
  const options = {headers: {'Content-Type': 'application/json'}}
  const { data: frameworks } = useFetch(`${API_URL}frameworks`, options, [])
  const { data: choices } = useFetch(`${API_URL}choices`, options, [])

  const date = context.selection?.createdAt ? new Date(context.selection.createdAt) : new Date()

  return (
    <BasePage>
      <Breadcrumb/>
      <div className={styles.container}>
        <div className={styles.chosenItemWraper}>
          <SectionTitle title={'Your Details'} size={SectionTitleSize.Small} style={SectionTitleStyle.Dark}/>
          <WizardOption label={'Created:'} value={formatDate(date)}/>
          <WizardOption label={'Route:'} value={toLocaleTitleCase(context.wizard.route as WizardRoute)}/>
          <WizardOption label={'Service Option:'} value={(frameworks ?? []).find((f: {_id: string}) => f._id === context.wizard.service)?.name}/>

          <WizardHeading heading={'SPECIFICATION:'}/>
          {Object.entries(context.wizard.specification).map(
            ([v, k]) => <WizardOption
              key={v}
              label={(choices ?? []).find((c: {_id: string}) => c._id === v)?.name}
              value={k}
            />
          )}

          <div className={styles.spacer}></div>

          <WizardHeading heading={'PROJECT DETAILS:'}/>
          <WizardOption label={'Project Budget'} value={formatCurrency(context.wizard.details.budget !== '' ? context.wizard.details.budget : undefined)}/>
          <WizardOption label={'Quantity'} value={formatNumber(context.wizard.details.quantity !== '' ? context.wizard.details.quantity : undefined)}/>
          <WizardOption label={'Location'} value={context.wizard.details.location ?? ''}/>

          <div className={styles.spacer}></div>

          <WizardHeading heading={'HISTORICAL DETAILS:'}/>
          <WizardOption label={'Cost'} value={context.wizard.historical.cost ? formatCurrency(context.wizard.historical.cost) : ''}/>
          <WizardOption label={'Procurement method'} value={toLocaleTitleCase(context.wizard.historical.procurement ?? '')}/>
          { context.wizard.historical.procurement === 'framework' ?
            <>
              <WizardOption label={'Framework'} value={context.wizard.historical.framework ?? ''}/>
              <WizardOption label={'Contractor'} value={context.wizard.historical.contractor ?? ''}/>
              <WizardOption label={'Performance'} value={String(context.wizard.historical.performance ?? '')}/>
            </>
            :
            null
          }

          <div className={styles.spacer}></div>

          <WizardOption
            label={'CONTRACTOR'}
            value={context.selection?.contractor ?? ''}
          />

          <div className={styles.amount}>
            <div className={styles.optionLabel}>Amount:</div>
            <div className={styles.price}>{formatCurrency(context.selection?.amount ?? 51234)}</div>
          </div>
          <div className={styles.row}>
            <Button colour={'green'} onClick={() => navigate(routes.wizard.path)} label={'Back'}/>
            <Button colour={'blue'} onClick={() => navigate('/terms')} label={'Proceed'}/>
          </div>
        </div>
      </div>
      <RequestCallback/>
    </BasePage>
  )
}

export { WizardChosenResults }
