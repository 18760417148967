import styles from './HeaderCallBackCTA.module.css'
import { ReactComponent as CallBack } from '../../images/callback.svg'

function HeaderCallBackCTA() {
  return (
    <div className={styles.ctaWrapper}>
      <CallBack />
      <div className={styles.cta}>BOOK A CALL BACK / VC</div>
    </div>
  )
}

export { HeaderCallBackCTA }
