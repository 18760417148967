import { Blog } from 'src/components/Blog/Blog'
import { Breadcrumb } from 'src/components/Breadcrumb/Breadcrumb'
import { RequestCallback } from 'src/components/RequestCallback/RequestCallback'
import { BasePage } from './BasePage/BasePage'

function BlogPage() {
  return (
    <BasePage>
      <Breadcrumb/>
      <Blog/>
      <RequestCallback/>
    </BasePage>
  )
}

export { BlogPage }
