import styles from './Partners.module.css'

import { BACKEND_URL } from 'src/util'

type Partner = {
  name?: string
  imageId: string
  link?: string
}

type PartnersProps = {
  partners: Partner[]
}

function Partners(props: PartnersProps) {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        {props.partners.map(p => {
          if (p.link)
            return (
              <a
                key={p.imageId}
                href={p.link}
              >
                <img
                  className={styles.logo}
                  src={`${BACKEND_URL}file/${p.imageId}`}
                  alt={p.name}
                  title={p.name}
                />
              </a>
            )
          return (
            <img
              key={p.imageId}
              className={styles.logo}
              src={`${BACKEND_URL}file/${p.imageId}`}
              alt={p.name}
              title={p.name}
            />
          )
        })}
      </div>
    </div>
  )
}

export { Partners }
export type { Partner }
