import { useState } from 'react'
import { Link } from 'react-router-dom'
import { UserPage } from 'src/pages/UserPage/UserPage'
import { routes } from 'src/routes'
import { BACKEND_URL, JSONObject } from 'src/util'
import { SectionTitle, SectionTitleSize, SectionTitleStyle } from '../SectionTitle/SectionTitle'
import { Text } from '../Text/Text'
import styles from './ForgottenPassword.module.css'

function ForgottenPassword() {
  const [state, setState] = useState<{[field: string]: string}>({})
  const [submitting, setSubmitting] = useState(false)
  const [done, setDone] = useState(false)
  const [error, setError] = useState<{field: string | null, message: string} | undefined>(undefined)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({...state, [event.target.name]: event.target.value})
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError(undefined)

    for (const field of (event.target as HTMLFormElement).elements) {
      if ((field as HTMLInputElement).reportValidity() === false)
        return
    }

    (async () => {
      setSubmitting(true)

      const fd = new FormData(event.target as HTMLFormElement)
      const data: {[field: string]: string} = {}
      fd.forEach((v, k) => data[k] = v as string)

      try {
        const response = await fetch((event.target as HTMLFormElement).action, {
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams(data)
        })
        setSubmitting(false)

        if (response.ok === false) {
          setError({field: null, message: response.statusText})
          return
        }

        const json: JSONObject = await response.json()
        if (json.success as boolean === false) {
          setError({field: json.field as string | null, message: json.error as string})
          return
        }

        setDone(true)
      }
      catch (error) {
        setError({field: null, message: (error as Error).message})
        setSubmitting(false)
        return
      }
    })()
  }

  return (
    <UserPage>
      <div className={styles.container}>
        <SectionTitle size={SectionTitleSize.Small} style={SectionTitleStyle.Dark} title={'FORGOTTEN PASSWORD'}/>
        <div className={styles.subtitle}>Enter your email address and we will send you a link to reset your password.</div>
        { error && error.field === null ? <Text className={styles.error}>{error.message}</Text> : null}

        <div className={styles.form_bottom}>
          { done ?
            <>
              <Text>Please check your inbox for a password reset email.</Text>
              <div className={styles.join}>
                <Link className={styles.login} to={routes.login.path}>Return to Login</Link>
              </div>
            </>
            :
            <form action={`${BACKEND_URL}forgotten-password`} method={'POST'} className={styles.form} onSubmit={onSubmit}>
              <div className={styles.fields}>
                <div className={styles.field}>
                  <label className={styles.label} htmlFor={'email'}>Email</label>
                  <input className={styles.input} type={'email'} name={'email'} onChange={onChange}/>
                  { error && error.field === 'email' ?
                    <Text className={styles.error}>{error.message}</Text>
                    :
                    null
                  }
                </div>

                <div className={styles.submitAndPassword}>
                  <button className={styles.submit} disabled={submitting}>RESET PASSWORD</button>
                </div>
                <div className={styles.join}>
                  <Link className={styles.login} to={routes.login.path}>Return to Login</Link>
                </div>
              </div>
            </form>
          }
        </div>
      </div>
    </UserPage>
  )
}

export { ForgottenPassword }


