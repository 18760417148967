import { BasePage } from '../BasePage/BasePage'
import { Breadcrumb } from 'src/components/Breadcrumb/Breadcrumb'
import { useAppContext } from 'src/Context'
import {
  SectionTitle,
  SectionTitleSize,
  SectionTitleStyle
} from 'src/components/SectionTitle/SectionTitle'
import {
  CMSSection,
  CMSSectionBackground
} from 'src/components/CMSSection/CMSSection'

import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { API_URL, formatNumber, formatURL } from 'src/util'
import { NumberField } from 'src/components/NumberField/NumberField'
import { TextArea } from 'src/components/TextArea/TextArea'
import { Button } from 'src/wizard/Button/Button'
import { Text } from 'src/components/Text/Text'

import {
  Period,
  Template,
  TemplateCountQuestion,
  TemplatePercentQuestion,
  TemplateQuestionType
} from 'src/types'

import styles from './ContractReport.module.css'
import { routes } from 'src/routes'
import { PercentField } from 'src/components/PercentField/PercentField'
import { Select } from 'src/components/Select/Select'
import { InputField } from 'src/components/InputField/InputField'

type LabelProps = {
  label: string
  required: boolean
  children: React.ReactNode
}

function Label(props: LabelProps) {
  return (
    <label className={styles.label}>
      <span className={styles.question}>
        {props.label}
        {props.required ? '*' : ''}
      </span>
      {props.children}
    </label>
  )
}

type FieldFreeTextProps = {
  question: TemplateCountQuestion
  state: QuestionResponse | undefined
  onChange: (response: QuestionResponse) => void
}

function FieldFreeText(props: FieldFreeTextProps) {
  const value = { explanation: null, value: '', ...props.state }

  return (
    <div>
      <Label label={props.question.question} required={false}>
        <InputField
          type='number'
          steps={true}
          onChange={(event) => {
            props.onChange({
              ...value,
              value:
                Number(event.target.value) > 99999999
                  ? (99999999).toString()
                  : event.target.value
            })
          }}
          value={value.value}
        />
      </Label>
    </div>
  )
}

type FieldCountProps = {
  question: TemplateCountQuestion
  state: QuestionResponse | undefined
  onChange: (response: QuestionResponse) => void
}

function FieldCount(props: FieldCountProps) {
  const value = { explanation: null, value: '', ...props.state }

  const colour =
    (Number(value.value) ?? 0) > props.question.minimum
      ? '#fc0000'
      : (Number(value.value) ?? 0) > props.question.target
      ? '#ff6c00'
      : '#4baf4f'

  return (
    <div>
      <Label label={props.question.question} required={true}>
        <NumberField
          onChange={(event) => {
            props.onChange({
              ...value,
              value: event.target.value !== '' ? event.target.value : ''
            })
          }}
          value={value.value ? Number(value.value) : ''}
          style={{ color: colour }}
          required={true}
        />
        <span className={styles.help}>
          Enter the number of times this occurred. Your target is{' '}
          {props.question.target} occurrence
          {props.question.target === 1 ? '' : 's'} and we expect{' '}
          {props.question.minimum} occurence
          {props.question.minimum === 1 ? '' : 's'} at maximum.
        </span>
      </Label>
      {value.value !== '' &&
      props.question.explanation !== null &&
      Number(value.value) > props.question.explanation ? (
        <Label label={'Please provide an explanation'} required={true}>
          <TextArea
            onChange={(event) => {
              props.onChange({ ...value, explanation: event.target.value })
            }}
            value={value.explanation ?? ''}
            required={true}
          />
          <span className={styles.help}>
            Please provide an explanation or details.
          </span>
        </Label>
      ) : null}
    </div>
  )
}

type FieldYesNoProps = {
  question: TemplatePercentQuestion
  state: QuestionResponse | undefined
  reverse?: boolean
  onChange: (response: QuestionResponse) => void
}

function FieldYesNo(props: FieldYesNoProps) {
  const value = {
    explanation: null,
    value: props.reverse ? '1' : '0',
    ...props.state
  }
  return (
    <div>
      <Label label={props.question.question} required={true}>
        <Select
          value={value.value ?? 0}
          options={[
            { value: props.reverse ? 1 : 0, label: 'No' },
            { value: props.reverse ? 0 : 1, label: 'Yes' }
          ]}
          onChange={(event) => {
            props.onChange({ ...value, value: event.target.value })
          }}
        />
      </Label>

      {!props.reverse && value.value == '0' && (
        <Label label={'Please provide an explanation'} required={true}>
          <TextArea
            onChange={(event) => {
              props.onChange({ ...value, explanation: event.target.value })
            }}
            value={value.explanation ?? ''}
            required={true}
          />
          <span className={styles.help}>
            Please provide an explanation or details.
          </span>
        </Label>
      )}
    </div>
  )
}

type FieldPercentProps = {
  question: TemplatePercentQuestion
  state: QuestionResponse | undefined
  onChange: (response: QuestionResponse) => void
}

function FieldPercent(props: FieldPercentProps) {
  const value = { explanation: null, value: '', ...props.state }
  const colour =
    (Number(value.value) ?? 0) < props.question.minimum
      ? '#fc0000'
      : (Number(value.value) ?? 0) < props.question.target
      ? '#ff6c00'
      : '#4baf4f'

  return (
    <div>
      <Label label={props.question.question} required={true}>
        <PercentField
          onChange={(event) => {
            props.onChange({
              ...value,
              value: event.target.value !== '' ? event.target.value : ''
            })
          }}
          value={value.value ?? ''}
          step={1}
          min={0}
          required={true}
          style={{ color: colour }}
        />
        <span className={styles.help}>
          Enter the percentage as a whole value. Your target is{' '}
          {props.question.target}% and we expect {props.question.minimum}% at
          minimum.
        </span>
      </Label>
      {value.value !== '' &&
      props.question.explanation !== null &&
      Number(value.value) < props.question.explanation ? (
        <Label label={'Please provide an explanation'} required={true}>
          <TextArea
            onChange={(event) => {
              props.onChange({ ...value, explanation: event.target.value })
            }}
            value={value.explanation ?? ''}
            required={true}
          />
          <span className={styles.help}>
            Please provide an explanation or details.
          </span>
        </Label>
      ) : null}
    </div>
  )
}

type QuestionResponse = {
  value: string
  explanation: string | null
}

function ContractReport() {
  const params = useParams()
  const context = useAppContext()
  const navigate = useNavigate()
  const contract = context.contracts.find((c) => c._id === params.contract)

  const [template, setTemplate] = useState<Template | null>(null)

  const [progress, setProgress] = useState<number | ''>('')
  const [state, setState] = useState<QuestionResponse[]>([])

  const periods =
    contract?.periods.filter((p) => {
      if (
        contract.submissions.find((s) => s.period === p && s.submitted) !==
        undefined
      )
        return false

      // return (new Date()).getTime() > (new Date(`${p}-${contract.reportDueDay}`)).getTime()
      return true
    }) ?? []

  const [period, setPeriod] = useState(periods[0])
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    ;(async function () {
      if (!contract) return
      const response = await fetch(`${API_URL}template/${contract.templateId}`)

      if (!response.ok) return

      const template = (await response.json()) as Template
      setTemplate(template)

      if (contract.openReport) setState(contract.openReport.responses)
      else setState(new Array(template.questions.length))
    })()
  }, [contract])

  // let period : string | null = contract?.periods.find(p => {
  //   if (contract.submissions.find(s => s.period === p && s.submitted) !== undefined)
  //     return false

  //   return true
  // }) ?? null

  // if (contract === undefined || template === null || period === null)
  //   return null

  if (contract === undefined || template === null) return null

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError(null)
    setSubmitting(true)

    // for some reason this needs casting to get name, even though it's set to that type?
    const submit = true // (event.target as HTMLButtonElement).name === 'submit'

    if (contract === undefined) return

    let valid = null

    try {
      const data = new URLSearchParams()
      data.append('username', context.user?.username as string)
      data.append('contractId', contract._id)
      data.append('progress', progress.toString())
      data.append('state', JSON.stringify(state))
      data.append('period', period as string)
      data.append('submission', contract.openReport?._id ?? '')
      data.append('submit', JSON.stringify(submit))

      const response = await fetch(`${API_URL}report`, {
        method: 'post',
        headers: {
          Authorization: `Token ${context.user?.token}`
        },
        body: data
      })

      if (response.ok === false) {
        setError(response.statusText)
        return
      }

      const json = await response.json()

      if (json.success === false) {
        setError(json.message)
        return
      }

      // update local state -- modify by reference
      const contracts = [...context.contracts]
      const index = contracts.findIndex((c) => c._id === contract._id)

      const updated = { ...json.contract }
      valid = updated.programmeValid
      if (index >= 0) {
        contracts[index] = {
          ...updated,
          siteStartDate: new Date(updated.siteStartDate as string),
          endDate: new Date(updated.endDate as string),
          reportStartDate: new Date(updated.reportStartDate as string),
          dueFirst: new Date(updated.dueFirst as string),
          dueNext: new Date(updated.dueNext as string),
          createdAt: new Date(updated.createdAt as string)
        }
      } else {
        // this should never happen, but if it does just add it on the end
        contracts.push({
          ...updated,
          siteStartDate: new Date(updated.siteStartDate as string),
          endDate: new Date(updated.endDate as string),
          reportStartDate: new Date(updated.reportStartDate as string),
          dueFirst: new Date(updated.dueFirst as string),
          dueNext: new Date(updated.dueNext as string),
          createdAt: new Date(updated.createdAt as string)
        })
      }
      context.setContext({ ...context, contracts })
    } catch {
      setError(
        'A network error occurred. Please check your internet connection and try again.'
      )
      return
    } finally {
      setSubmitting(false)
      if (valid === null || valid === true) {
        navigate(routes.member.path)
      } else {
        if (routes.member.children) {
          const url = formatURL(routes.member.children.programme.path, {
            contract: contract._id
          })
          navigate(url)
        }
      }
    }
  }

  //period = period ? period : '' as Period

  const onTrack = (function () {
    if (contract.useProgramme === false) return null

    if (contract.programme?.allocation[period] == null) return null

    if (progress === '') return null

    return progress >= contract.programme.allocation[period]
  })()

  if (periods.length === 0) {
    return (
      <BasePage>
        <Breadcrumb />

        <CMSSection
          primary={
            <>
              <SectionTitle
                title={contract.name}
                size={SectionTitleSize.Small}
                style={SectionTitleStyle.Dark}
              />
              <Text>All reports are sent.</Text>
            </>
          }
          background={CMSSectionBackground.White}
        />
      </BasePage>
    )
  }

  return (
    <BasePage>
      <Breadcrumb />

      <CMSSection
        primary={
          <>
            <SectionTitle
              title={contract.name}
              size={SectionTitleSize.Small}
              style={SectionTitleStyle.Dark}
            />

            <form onSubmit={submit}>
              {/* <div>
                <div className={styles.period}>Period: {period}</div>
              </div> */}

              <div>
                <Label label={'Reporting Period'} required={true}>
                  <Select
                    value={period}
                    options={periods.map((p) => ({ label: p, value: p }))}
                    onChange={(e) => setPeriod(e.target.value as Period)}
                  />
                </Label>
              </div>

              {contract.useProgramme ? (
                <div>
                  <Label label={'Jobs completed'} required={true}>
                    <NumberField
                      onChange={(event) => {
                        setProgress(
                          event.target.value !== ''
                            ? parseInt(event.target.value)
                            : ''
                        )
                      }}
                      value={progress}
                      step={1}
                      min={0}
                      required={true}
                      style={{
                        color:
                          onTrack === true
                            ? '#4baf4f'
                            : onTrack === false
                            ? '#fc0000'
                            : 'black'
                      }}
                    />
                    <span className={styles.help}>
                      Enter the number of jobs completed this reporting period
                      as a whole number. Your programme has{' '}
                      {formatNumber(contract.programme?.allocation[period])}{' '}
                      jobs scheduled for completion for this period.
                    </span>
                  </Label>
                </div>
              ) : null}

              {template.questions.map((q, i) => {
                const onChange = (response: QuestionResponse) => {
                  setState([
                    ...state.slice(0, i),
                    response,
                    ...state.slice(i + 1)
                  ])
                }
                switch (q.type) {
                  case TemplateQuestionType.Count:
                    return (
                      <FieldCount
                        key={i}
                        question={q}
                        state={state[i]}
                        onChange={onChange}
                      />
                    )
                  case TemplateQuestionType.Percent:
                    return (
                      <FieldPercent
                        key={i}
                        question={q}
                        state={state[i]}
                        onChange={onChange}
                      />
                    )
                  case TemplateQuestionType.YesNo:
                    return (
                      <FieldYesNo
                        key={i}
                        question={q}
                        state={state[i]}
                        onChange={onChange}
                      />
                    )
                  case TemplateQuestionType.YesNoReverse:
                    return (
                      <FieldYesNo
                        key={i}
                        reverse={true}
                        question={q}
                        state={state[i]}
                        onChange={onChange}
                      />
                    )
                  case TemplateQuestionType.FreeText:
                    return (
                      <FieldFreeText
                        key={i}
                        question={q}
                        state={state[i]}
                        onChange={onChange}
                      />
                    )
                }
              })}

              {onTrack === false ? (
                <Text>
                  If you are falling behind your planned schedule and are likely
                  to need an extension or to change your contract, please{' '}
                  <a href={routes.contact.path} className={styles.link}>
                    contact us
                  </a>{' '}
                  as soon as possible.
                </Text>
              ) : null}

              {error ? <Text className={styles.error}>{error}</Text> : null}
              <div className={styles.buttons}>
                {/* <Button name={'save'} submit={true} colour={'blue'} label={'Save for later'} disabled={submitting || contract === undefined || template === null} formNoValidate={true} /> */}
                <Button
                  name={'submit'}
                  submit={true}
                  colour={'green'}
                  label={'Submit'}
                  disabled={
                    submitting || contract === undefined || template === null
                  }
                />
              </div>
            </form>
          </>
        }
        background={CMSSectionBackground.White}
      />
    </BasePage>
  )
}

export { ContractReport }
