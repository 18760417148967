import useFetch from 'use-http'
import { SectionTitle, SectionTitleSize, SectionTitleStyle } from '../SectionTitle/SectionTitle'
import { BACKEND_URL, formatDate } from 'src/util'

import styles from './Blog.module.css'

import logo from '../../images/logo.png'
import { Link } from 'react-router-dom'

type BlogProps = {
  image: string
  title: string
  excerpt: string
  updated: Date
  link: string
}

type PostSerialised = {
  _id: string
  title: string
  slug: string
  metatitle?: string
  metadescription?: string
  updated: string
  imageId?: string
  excerpt: string
}

function BlogEntry(props: BlogProps) {
  return (
    <Link to={props.link} className={styles.entry}>
      <div className={styles.image}>
        <img className={styles.responsive} src={props.image} alt={props.title} />
      </div>
      <div className={styles.details}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.excerpt}>{props.excerpt}</div>
        <div className={styles.row}>
          <Link to={props.link} className={styles.link}>Continue reading</Link>
          <div className={styles.date}>{formatDate(props.updated, {hour: undefined, minute: undefined})}</div>
        </div>
      </div>
    </Link>
  )
}

function Blog() {
  const { data } = useFetch(`${BACKEND_URL}api/posts`, [])

  return (
    <div className={styles.container}>
      <SectionTitle title={'NEWS AND EVENTS'} subtitle={'What we have been talking about lately'} size={SectionTitleSize.Small} style={SectionTitleStyle.LightBlue} />

      <div className={styles.grid}>
        {(data ?? []).map((post: PostSerialised) => {
          return (
            <BlogEntry
              image={post.imageId ? `${BACKEND_URL}file/${post.imageId}` : logo}
              title={post.title}
              excerpt={post.excerpt}
              updated={new Date(post.updated)}
              link={`/blog/${post.slug}`}
            />
          )
        })}
      </div>
    </div>
  )
}

export { Blog }
