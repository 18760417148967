import styles from './UserPage.module.css'

import riverside from '../../images/photos/riverside.png'
import riverside2x from '../../images/photos/riverside@2x.png'

function UserPage(props: {children: React.ReactNode}) {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <img className={styles.image} src={riverside} srcSet={`${riverside2x} 2x`}/>
        <div className={styles.overlay}></div>
        <a href={'/'}>
          <img className={styles.logo} src={'../../images/login-logo.png'} alt={''} />
        </a>
        <div className={styles.bottom}>
          <span className={styles.legal}>&copy; {(new Date()).getFullYear()} EASTERN PROCUREMENT</span>
          <span className={styles.divider}></span>
          <span className={styles.legal}>CO REG NO 08351985</span>
        </div>
      </div>

      <div className={styles.right}>
        {props.children}
      </div>
    </div>
  )
}

export { UserPage }


