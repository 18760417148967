import styles from './CMSSection.module.css'

enum CMSSectionBackground {
  White = 'white',
  Grey = 'grey',
  Blue = 'blue'
}

function backgroundColour(bg: CMSSectionBackground) {
  switch (bg) {
    case CMSSectionBackground.White:
      return styles.white
    case CMSSectionBackground.Grey:
      return styles.grey
    case CMSSectionBackground.Blue:
      return styles.blue
  }
}

type CMSSectionProps = {
  background: CMSSectionBackground
  reversed?: boolean
  primary: React.ReactNode
  secondary?: React.ReactNode
}

function CMSSection(props: CMSSectionProps) {
  return (
    <div className={`${styles.section} ${backgroundColour(props.background)}`}>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.content} ${props.reversed ? styles.reversed : ''}`}>
            {props.secondary !== undefined && (!Array.isArray(props.secondary) || (props.secondary as unknown[]).length) > 0
              ?
              <div className={styles.secondary}>
                {props.secondary}
              </div>
              :
              null
            }
            <div className={styles.primary}>
              {props.primary}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CMSSection, CMSSectionBackground }
export type { CMSSectionProps }
