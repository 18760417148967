import type { InputFieldProps } from '../InputField/InputField'
import styles from './NumberField.module.css'

type NumberFieldProps = Omit<InputFieldProps, 'type' | 'value' | 'pattern'> & {
  value: number | ''
  min?: number
  max?: number
  step?: 'any' | number
  style?: React.CSSProperties
  readonly?: boolean
}

function NumberField(props: NumberFieldProps) {
  return (
    <input
      className={styles.input}
      style={props.style}
      type={'number'}
      value={props.value}
      onChange={props.onChange}
      onWheel={e => e.currentTarget.blur()}
      onKeyDown={e => { (e.keyCode === 38 || e.keyCode === 40) && e.preventDefault() }}
      required={props.required}
      min={props.min}
      max={props.max}
      step={props.step ?? 'any'}
      readOnly={props.readonly ?? false}
    />
  )
}

export { NumberField }
