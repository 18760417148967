import React from 'react'
import styles from './InputField.module.css'

type InputFieldProps = {
  type: 'text' | 'email' | 'tel' | 'number'
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  placeholder?: string
  pattern?: string
  steps?: boolean
}

function InputField(props: InputFieldProps) {
  return (
    <input
      className={styles.input}
      type={props.type}
      value={props.value}
      onChange={props.onChange}
      required={props.required}
      placeholder={props.placeholder}
      pattern={props.pattern}
      step={props.steps ? 'any' : undefined}
    />
  )
}

export { InputField }
export type { InputFieldProps }
