import { useMemo } from 'react'
import { Progress } from '../Progress/Progress'

import styles from './PasswordStrength.module.css'


function passwordScore(password: string) {
  if (password.length < 8)
    return 0

  // 0 .. 0.75 for 8 to 24 characters
  let score = (password.length - 8) / 24

  // add an eigth for each group of characterss
  const tests = [/[a-z]/, /[A-Z]/, /\d/, /[^a-zA-Z0-9]/]
  for (const test of tests) {
    if (test.test(password))
      score += 0.125
  }

  return Math.min(score, 1.0)
}


function PasswordStrength({password}: {password: string}) {
  const score = useMemo(() => passwordScore(password), [password])

  const descriptions = [
    'very weak',
    'weak',
    'okay',
    'strong',
    'excellent'
  ]
  const colour = score < 0.5 ? '#fc0000' : score < 0.75 ? '#ff6c00' : '#4baf4f'

  return (
    <div>
      <Progress value={password.length >= 8 ? score : 0.0} max={1.0} barStyle={{background: colour}}/>
      {
        password.length >= 8 ?
          <div className={styles.score} style={{color: colour}}>Your password is {descriptions[Math.floor(score * 4)]}</div>
          :
          <div className={styles.score}>Minimum of 8 characters</div>
      }
    </div>
  )
}

export { PasswordStrength }
