import { Link, useLocation } from 'react-router-dom'
import { useAppContext } from 'src/Context'
import { buildTree, routes, Routing } from '../../routes'
import { toLocaleTitleCase } from '../../util'
import styles from './Breadcrumb.module.css'

function Breadcrumb() {
  const context = useAppContext()
  const tree = buildTree(routes, context)
  const location = useLocation()

  const names = location.pathname.split('/').slice(1)
  let level: {[key: string]: Routing} | undefined = tree
  const path = []
  for (const name of names) {
    if (!level)
      break

    const l: Routing | undefined = Object.values(level).find(l => l.slug === name)
    if (l) {
      path.push(l)
      level = l.children
    }
  }

  const terminal = path.pop()

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.breadcrumb}>
          <Link className={styles.crumb} to={'/'}>Home</Link>
          {path.map(p => {
            const title = p.title.split(' ').map(toLocaleTitleCase).join(' ')
            return (
              <div key={p.path} className={styles.pair}>
                <span className={styles.separator}></span>
                <Link className={styles.crumb} to={p.path}>{title}</Link>
              </div>
            )
          })}
          {terminal ?
            <div className={styles.pair}>
              <span className={styles.separator}></span>
              <span className={`${styles.crumb} ${styles.active}`}>{terminal.title.split(' ').map(toLocaleTitleCase).join(' ')}</span>
            </div>
            :
            null
          }
        </div>
      </div>
    </div>
  )
}

export { Breadcrumb }
