import { routes } from '../../routes'
import styles from './SignupSuccess.module.css'

import { SectionTitle, SectionTitleSize, SectionTitleStyle } from '../../components/SectionTitle/SectionTitle'
import { UserPage } from '../../pages/UserPage/UserPage'
import { useLocation } from 'react-router-dom'

function SignupSuccess() {
  const location = useLocation()
  const { permission } = location.state as {permission: string | null}

  return (
    <UserPage>
      <div className={styles.container}>
        <div className={styles.header}>
          <SectionTitle style={SectionTitleStyle.Dark} size={SectionTitleSize.Small} title={permission ? 'THANK YOU FOR JOINING' : 'THANK YOU FOR SIGNING UP'}/>
          {permission ?
            <a className={`${styles.link} ${styles.login}`} href={routes.login.path}>Login</a>
            :
            null
          }
        </div>
        { permission ?
          <div className={styles.subtitle}>You must verify your email address, check your email to verify. Then you may log in and use our benchmark & commission service.</div>
          :
          <div className={styles.subtitle}>Your account needs to be approved by our staff members before you can use our service. You'll be notified when your account has been reviewed. In the meantime you can verify your email address, check your email to verify.</div>
        }
      </div>
    </UserPage>
  )
}

export { SignupSuccess }


