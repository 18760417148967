import styles from './WizardResultCard.module.css'
import { ReactComponent as Arrow } from '../../images/carousel-arrow.svg'
import { BACKEND_URL, formatCurrency } from '../../util'

type WizardResult = {
  logo?: string
  contractor: string // name
  contractorId: string
  amount: number

  priceScore: number
  quality: string | null
  performance: number | null

  service: string
  specification: { [field: string]: string }

  createdAt: string
}

type WizardResultCardProps = WizardResult & {
  position: number
  goAhead: () => void
  other?: () => void
}

function WizardResultCard(props: WizardResultCardProps) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.top}>
          {props.logo ? (
            <div>
              <img
                className={styles.logo}
                src={`${BACKEND_URL}file/${props.logo}`}
                alt={props.contractor}
              />
            </div>
          ) : null}
          <div className={styles.name}>{props.contractor}</div>
        </div>

        <div className={styles.row}>
          <div>
            <div>PROJECT PRICE POSITION</div>
            <div className={styles.value}>{props.position}</div>
          </div>
          <div>
            <div>PROJECT COST</div>
            <div className={styles.amount}>{formatCurrency(props.amount)}</div>
          </div>
          {props.quality ? (
            <div>
              <div>FRAMEWORK SCORE</div>
              <div className={styles.value}>{props.quality}</div>
            </div>
          ) : null}
          {props.performance ? (
            <div>
              <div>CUSTOMER SATISFACTION</div>
              <div className={styles.value}>{props.performance}</div>
            </div>
          ) : null}
        </div>

        <div className={styles.row}>
          <div className={styles.choose}>
            <div onClick={props.goAhead}>GO AHEAD WITH THIS CONTRACTOR</div>
            <Arrow className={styles.arrow} />
          </div>
          {props.other ? (
            <div className={styles.other} onClick={props.other}>
              CONSIDER OTHER CONTRACTORS?
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export { WizardResultCard }
export type { WizardResult }
