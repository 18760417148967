import { useEffect, useLayoutEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Outlet
} from 'react-router-dom'
import { NotFound } from './pages/NotFound'
import { buildTree, routes } from './routes'
import type { Routing } from './routes'
import { AppContextProvider, ContextValue, useAppContext } from './Context'
import { API_URL, BACKEND_URL, JSONObject } from './util'
import type { Contract } from './types'
import { DynamicPage } from './pages/DynamicPage'
import CookieConsent from 'react-cookie-consent'


function ScrollToTop() {
  const location = useLocation()
  const context = useAppContext()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  // set window title
  useEffect(() => {
    const steps = location.pathname.split('/').filter(s => s)

    const root = steps.shift()
    const tree = buildTree(routes, context)
    let route = tree[root ?? 'homepage']

    for (const step of steps) {
      if (route && route.children)
        route = route.children[step]
    }

    if (route === undefined)
      return
    // if a page has a metatitle, use that
    document.title = `${route.metatitle || route.title} | Eastern Procurement`

    const description = document.querySelector('meta[name=description]')
    if (description)
      (description as HTMLMetaElement).content = route.metadescription ?? ''

  }, [location, routes, context])

  return null
}


// react-router-dom v6 does not allow JSX elements other than fragments and Route
// to be inside Routes, so use a normal free-form function instead of emitting
// an intermediate component
function routingList(tree: {[name: string]: Routing}) {
  return (
    <>
      {Object.values(tree).map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={route.children ? <Outlet /> : route.page}
          >
            {route.children ? <Route index element={route.page} /> : null}
            {route.children ? routingList(route.children) : null}
          </Route>
        )
      })}
    </>
  )
}

function newRouting(routes: {[key: string]: Routing}, context: Omit<ContextValue, 'setContext'>) {
  return (
    <>
      {routingList(routes)}
      {Object.values(context.pages).map(p => {
        return <Route
          key={p._id}
          path={p.path}
          element={<DynamicPage title={p.title} pageId={p._id}/>}
        />
      })}
    </>
  )
}

function App() {
  const [context, setContextInternal] = useState<Omit<ContextValue, 'setContext'>>({
    loaded: false,
    menus: {},
    pages: {},
    user: null,
    wizard: {
      route: null,
      contractor: null,
      service: null,
      specification: {},
      details: {
        budget: 0,
        location: '',
        quantity: 1
      },
      historical: {
        cost: 0,
        procurement: null,
        framework: null,
        contractor: null,
        performance: null
      }
    },
    results: [],
    selection: null,
    contracts: []
  })

  const setContext = (newContext: Omit<ContextValue, 'setContext'>) => {
    setContextInternal(newContext)

    sessionStorage.setItem('context', JSON.stringify(newContext))
  }

  useEffect(() => {
    // hydrate context from session storage
    const ctx = sessionStorage.getItem('context')
    if (ctx)
      // use internal, we don't need to save
      setContextInternal(JSON.parse(ctx, (_, v) => {
        // convert timestamps to Date objects
        if (typeof v === 'string' && /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.exec(v))
          return new Date(v)
        return v
      }))

    let user = JSON.parse(localStorage.getItem('user') ?? 'null');

    (async function () {
      if (user?.token !== undefined) {
        const check = await fetch(`${BACKEND_URL}check`, {
          method: 'post',
          headers: {
            Authorization: `Token ${user.token}`
          },
          body: new URLSearchParams([['username', user.username]])
        })

        // if the request failed, do nothing
        if (check.ok)
        {
          const json = await check.json()

          // if we didn't auth, remove the old data
          if (json.success === false) {
            user = null
            localStorage.removeItem('user')
          }
          else {
            user = json.user
          }
        }
      }

      const pages = await fetch(`${BACKEND_URL}api/pages/`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const pageData = pages.ok ? await pages.json() : context.pages
      const menus = await fetch(`${BACKEND_URL}api/menus/`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const menuData = menus.ok ? await menus.json() : context.menus

      let contracts: Contract[] = []
      if (user?.token !== undefined) {
        const contractFetch = await fetch(`${API_URL}contracts`, {
          method: 'post',
          headers: {
            Authorization: `Token ${user.token}`
          },
          body: new URLSearchParams([['username', user.username]])
        })

        const contractData = contractFetch.ok ? (await contractFetch.json() as JSONObject[]) : context.contracts
        contracts = contractData.map(c => {
          return {
            ...c,
            siteStartDate: new Date(c.siteStartDate as string),
            endDate: new Date(c.endDate as string),
            reportStartDate: new Date(c.reportStartDate as string),
            dueFirst: new Date(c.dueFirst as string),
            dueNext: new Date(c.dueNext as string),
            createdAt: new Date(c.createdAt as string)
          } as Contract
        })
      }

      setContext({...context, loaded: true, user, pages: pageData, menus: menuData, contracts})
    })()
  }, [])

  // don't flash 404 while loading content
  if (!context.loaded)
    return null

  return (
    <AppContextProvider context={{ ...context, setContext } as ContextValue}>
      <CookieConsent 
        style={{ background: '#2a3e57', fontFamily: 'Montserrat, sans-serif' }}
        buttonStyle={{ color: '#fff', background: '#d22524', fontSize: '13px' }}
        expires={150}>This website uses cookies to ensure its proper operation and tracking cookies to understand how you interact with it.</CookieConsent>
      <Router>
        <ScrollToTop />
        <Routes>
          {newRouting(routes, context)}
          <Route path={'*'} element={<NotFound />} />
        </Routes>
      </Router>
    </AppContextProvider>
  )
}

export { App }
