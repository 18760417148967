import { useState } from 'react'

import styles from './Carousel.module.css'

import { ReactComponent as Arrow } from '../../images/carousel-arrow.svg'
import { Link } from 'react-router-dom'
import { BACKEND_URL } from 'src/util'
import { useWindowDimensions } from 'src/useWindowDimensions'

type CarouselItem = {
  caption: string
  imageId: string
  link: string
}

function CarouselItem({item}: {item: CarouselItem}) {
  return (
    <Link to={item.link} className={styles.item}>
      <img className={styles.image} src={`${BACKEND_URL}file/${item.imageId}`}/>
      <div className={styles.gradient}></div>
      <div className={styles.overlay}></div>
      <div className={`${styles.caption} ${styles.stroke}`}>{item.caption}</div>
      <Arrow className={styles.arrow}/>
    </Link>
  )
}

function Carousel({items}: {items: CarouselItem[]}) {
  const { width } = useWindowDimensions()
  const [position, setPosition] = useState(0)
  const max = Math.max(0, items.length - 2)

  const click = (delta: number) => {
    return () => {
      const p = position + delta
      if (p >= 0 && p <= max)
        setPosition(position + delta)
    }
  }

  const itemWidth = width <= 780 ? 333.75 : 445

  return (
    <div className={styles.container}>
      <div className={styles.overflow}>
        <div className={styles.scroller}>
          <div className={styles.items} style={{left: (itemWidth + 35) * -position}}>
            {items.map(item => <CarouselItem key={item.imageId} item={item}/>)}
          </div>
        </div>
      </div>
      <div className={`${styles.button} ${styles.prev} ${position > 0 ? styles.active : ''}`} onClick={click(-1)}>
        <Arrow className={styles.left}/>
      </div>
      <div className={`${styles.button} ${styles.next} ${position < max ? styles.active : ''}`} onClick={click(1)}>
        <Arrow/>
      </div>
    </div>
  )
}

export { Carousel }
export type { CarouselItem }
