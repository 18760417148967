import { BasePage } from './BasePage/BasePage'
import { MultiForm } from '../components/MultiForm/MultiForm'
import { RequestCallback } from 'src/components/RequestCallback/RequestCallback'

function WizardPage() {
  return (
    <BasePage>
      <MultiForm/>
      <RequestCallback/>
    </BasePage>
  )
}

export { WizardPage }
