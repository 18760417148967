import styles from './Chairman.module.css'

type Chairman = {
  image: string
  name: string
  position: string
}

function Chairman(props: Chairman) {
  return (
    <div className={styles.wrapper}>
      <img  className={styles.responsive} src={props.image}/>
      <div className={styles.overlay}></div>
      <div className={styles.nameAndPositioWrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.innerWrapper2}>
            <div className={styles.name}>{props.name}</div>
            <div className={styles.position}>{props.position}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Chairman }


