import styles from './Text.module.css'

type Props = {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

function Text(props: Props) {
  return (
    <pre
      className={`${styles.text} ${props.className ?? ''}`}
      style={props.style}
    >
      {props.children}
    </pre>
  )
}

function SectionText({
  children,
  style
}: {
  children: React.ReactNode
  style?: React.CSSProperties
}) {
  return (
    <Text className={styles.section} style={style}>
      {children}
    </Text>
  )
}

export { Text, SectionText }
