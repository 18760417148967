// a bunch of types copies from the backend project
type DocumentID = string

enum TemplateQuestionType {
  Percent = 'percent',
  Count = 'count',
  YesNo = 'yesNo',
  YesNoReverse = 'yesNoReverse',
  FreeText = 'freeText'
}

type TemplateBaseQuestion = {
  question: string
  type: TemplateQuestionType
}

type TemplateCountQuestion = TemplateBaseQuestion & {
  target: number
  minimum: number
  explanation: number | null
}

type TemplatePercentQuestion = TemplateBaseQuestion & {
  target: number // the target %
  minimum: number // the minimum %
  explanation: number | null // threshold on which they need to justify themselves
}

type TemplateQuestion = TemplateCountQuestion | TemplatePercentQuestion

type Template = {
  _id: DocumentID
  name: string
  questions: TemplateQuestion[]
}

type QuestionResponse = {
  // store a copy of the question for data consistency reasons
  question: TemplateQuestion
  value: string
  explanation: string | null
}

type Period = string //`${string}-${string}` // YYYY-MM

type KpiSubmission = {
  _id: DocumentID
  templateId: DocumentID
  contractId: DocumentID
  contractorId: DocumentID

  period: Period
  submitted: Date | null

  completed: number | undefined
  responses: QuestionResponse[]
  createdAt: Date
}

type Programme = {
  updatedAt: Date
  updatedBy: DocumentID

  // a target of completion for each month
  // can be edited going forwards, but past allocations cannot be changed
  allocation: { [period: string]: number }
}

type Contract = {
  // from Model
  _id: DocumentID
  archived: boolean
  version: number
  createdAt: Date
  createdBy: Date

  name: string // a name or identifier for the contract

  number: number | undefined // a unique numeric id for the contract, shared with OpenCRM
  // required field, but only new

  clientId: DocumentID | undefined // the client who this contract is for
  // can be undefined on the db level since old contracts didn't have this
  // but is required in the form

  contractorId: DocumentID // the contractor who owns this contract
  templateId: DocumentID // the template for KPI reporting

  // if the template of this contract uses programmes, a quantity is required
  // this is then used to track progress
  quantity: number | undefined
  // our programme, if we have one
  programme: Programme | undefined

  siteStartDate: Date // when does the work start
  reportStartDate: Date // when they gotta start sending KPI reports
  endDate: Date // date the contract ends

  reportDueDay: number // day of month the reports are due

  // injected stuff
  periods: Period[]
  contractProgrammePeriods: Period[]
  submissions: KpiSubmission[]
  dueFirst: Date
  dueNext: Date
  openReport: KpiSubmission | null
  useProgramme: boolean
  programmeValid: boolean
  complete: boolean
  contractLastReport: KpiSubmission | null
}

export { TemplateQuestionType }
export type {
  TemplateBaseQuestion,
  TemplateCountQuestion,
  TemplatePercentQuestion,
  TemplateQuestion,
  Template,
  QuestionResponse,
  Period,
  KpiSubmission,
  Programme,
  Contract
}
