import type { InputFieldProps } from '../InputField/InputField'
import styles from './PercentField.module.css'

type PercentFieldProps = Omit<InputFieldProps, 'type' | 'value' | 'pattern'> & {
  value: string
  min?: number
  max?: number
  step?: 'any' | number
  style?: React.CSSProperties
}

function PercentField(props: PercentFieldProps) {
  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        style={props.style}
        type={'number'}
        value={props.value}
        onChange={props.onChange}
        onWheel={(e) => e.currentTarget.blur()}
        onKeyDown={(e) => {
          ;(e.keyCode === 38 || e.keyCode === 40) && e.preventDefault()
        }}
        required={props.required}
        min={props.min}
        max={props.max}
        step={props.step ?? 'any'}
      />
      <div className={styles.icon}>%</div>
    </div>
  )
}

export { PercentField }
