import { Header } from '../../components/Header/Header'
import { Footer } from '../../components/Footer/Footer'

type BasePageProps = {
  title?: React.ReactNode
  children: React.ReactNode
}

function BasePage(props: BasePageProps) {
  return (
    <>
      <Header/>
      {props.title}
      {props.children}
      <Footer />
    </>
  )
}

export { BasePage }
export type { BasePageProps }
