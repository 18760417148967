import { BasePage } from '../BasePage/BasePage'
import { Breadcrumb } from 'src/components/Breadcrumb/Breadcrumb'
import { useAppContext } from 'src/Context'
import { SectionTitle, SectionTitleSize, SectionTitleStyle } from 'src/components/SectionTitle/SectionTitle'
import { CMSSection, CMSSectionBackground } from 'src/components/CMSSection/CMSSection'

import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { API_URL, formatDate, formatNumber } from 'src/util'
import { Button } from 'src/wizard/Button/Button'
import { Text } from 'src/components/Text/Text'

import styles from './ContractProgramme.module.css'
import { routes } from 'src/routes'
import { NumberField } from 'src/components/NumberField/NumberField'
import { Programme } from 'src/types'


type LabelProps = {
  label: string
  required: boolean
  children: React.ReactNode
}

function Label(props: LabelProps) {
  return (
    <label className={styles.label}>
      <span className={styles.question}>{props.label}{props.required ? '*' : ''}</span>
      {props.children}
    </label>
  )
}


function HelpText({text}: {text: string}) {
  return <span className={styles.help}>{text}</span>
}


function ContractProgramme() {
  const params = useParams()
  const context = useAppContext()
  const navigate = useNavigate()
  const contract = context.contracts.find(c => c._id === params.contract)

  const [state, setState] = useState<Partial<Programme>>({})
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (contract && contract.programme) {
      const initial = contract.programme

      for (const period of contract.contractProgrammePeriods) {
        const submission = contract.submissions.find(s => s.period === period)
        const behind = !!(submission?.completed && submission.completed < contract.programme.allocation[period])
        const value = (behind ? submission.completed : contract.programme?.allocation[period]) ?? 0
        initial.allocation[period] = value
      }

      setState(initial)
    }
  }, [contract])

  if (contract === undefined)
    return null

  const total = Object.values(state.allocation ?? {}).reduce((count, period) => count + period, 0)

  // evenly distribute the outstanding jobs between the remaining periods
  // any extras automatically add to the first months
  const distribute = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const set = {...state}
    if (set.allocation === undefined)
      set.allocation = {}

    const remaining = (contract.quantity as number) - contract.submissions.reduce((a, s) => a + (s.completed ?? 0), 0)
    const between = contract.contractProgrammePeriods.filter(p => contract.submissions.find(s => s.period === p) === undefined)
    const each = Math.floor(remaining / between.length)
    const extra = remaining - each * between.length
    for (const [index, period] of between.entries()) {
      set.allocation[period] = each
      if (index < extra)
        ++set.allocation[period]
    }

    setState(set)
    return false
  }

  const submit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setError(null)
    setSubmitting(true)

    try {
      if (contract === undefined)
        return

      // hc will send quantity as a string instead of an int
      if (contract.quantity && total != contract.quantity) {
        const text = total < contract.quantity ? 'short' : 'over'
        const diff = Math.abs(contract.quantity - total)
        setError(`Programme must allocate ${formatNumber(contract.quantity)} jobs, ${formatNumber(diff)} jobs ${text}.`)
        return
      }

      const data = new URLSearchParams()
      data.append('username', context.user?.username as string)
      data.append('contractId', contract._id)
      data.append('state', JSON.stringify(state))

      const response = await fetch(`${API_URL}programme`, {
        method: 'post',
        headers: {
          Authorization: `Token ${context.user?.token}`
        },
        body: data
      })

      if (response.ok === false) {
        setError(response.statusText)
        return
      }

      const json = await response.json()

      if (json.success === false) {
        setError(json.message)
        return
      }

      // update local state -- modify by reference
      const contracts = [...context.contracts]
      const index = contracts.findIndex(c => c._id === contract._id)

      const updated = {...json.contract}
      if (index >= 0) {
        contracts[index] = {
          ...updated,
          siteStartDate: new Date(updated.siteStartDate as string),
          endDate: new Date(updated.endDate as string),
          reportStartDate: new Date(updated.reportStartDate as string),
          dueFirst: new Date(updated.dueFirst as string),
          dueNext: new Date(updated.dueNext as string),
          createdAt: new Date(updated.createdAt as string)
        }
      }
      else { // this should never happen, but if it does just add it on the end
        contracts.push({
          ...updated,
          siteStartDate: new Date(updated.siteStartDate as string),
          endDate: new Date(updated.endDate as string),
          reportStartDate: new Date(updated.reportStartDate as string),
          dueFirst: new Date(updated.dueFirst as string),
          dueNext: new Date(updated.dueNext as string),
          createdAt: new Date(updated.createdAt as string)
        })
      }
      context.setContext({...context, contracts})
      navigate(routes.member.path)
    }
    catch {
      setError('A network error occurred. Please check your internet connection and try again.')
      return
    }
    finally {
      setSubmitting(false)
    }
  }

  const text = contract?.quantity ? (total < contract.quantity ? 'short' : 'over') : ''
  const diff = contract?.quantity ? (Math.abs(contract.quantity - total)) : 0

  return (
    <BasePage>
      <Breadcrumb/>

      <CMSSection
        primary={
          <>
            <SectionTitle title={`${contract.name} Programme`} size={SectionTitleSize.Small} style={SectionTitleStyle.Dark} />

            <Text>{`Your contract is scheduled to complete ${formatNumber(contract.quantity)} jobs by ${formatDate(contract.endDate, {minute: undefined, hour: undefined})}. Programme must allocate ${formatNumber(contract.quantity)} jobs, ${formatNumber(diff)} jobs ${text}. You must submit a programme of how many jobs you will complete each month. If you fall behind on your programme you must amend your programme.`}</Text>
            <form>
              <div className={styles.grid}>
                {contract.contractProgrammePeriods.map(p => {
                  const submission = contract.submissions.find(s => s.period === p)

                  return (
                    <div key={p}>
                      <Label label={p} required={true}>
                        <NumberField
                          onChange={e => setState({...state, allocation: {...state.allocation, [p]: e.target.value.length ? parseInt(e.target.value) : 0}})}
                          value={state?.allocation ? state?.allocation[p] : ''}
                          required={true}
                          readonly={submission !== undefined}
                        />
                        {submission !== undefined ?
                          <HelpText text={'Past entries cannot be modified.'}/>
                          :
                          null
                        }
                      </Label>
                    </div>
                  )
                })}
              </div>

              { error ?
                <Text className={styles.error}>{error}</Text>
                :
                null
              }
              <div className={styles.buttons}>
                <Button label={'Distribute Equally'} onClick={distribute} colour={'blue'} formNoValidate={true}/>
                <Button onClick={submit} name={'submit'} colour={'green'} label={'Submit'} disabled={submitting || contract === undefined}/>
              </div>
            </form>
          </>
        }
        background={CMSSectionBackground.White}
      />
    </BasePage>
  )
}

export { ContractProgramme }


