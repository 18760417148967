import { BasePage } from '../BasePage/BasePage'
import { Breadcrumb } from 'src/components/Breadcrumb/Breadcrumb'
import { useAppContext } from 'src/Context'
import { formatDate, formatURL } from '../../util'
import { SectionTitle, SectionTitleSize, SectionTitleStyle } from 'src/components/SectionTitle/SectionTitle'
import { CMSSection, CMSSectionBackground } from 'src/components/CMSSection/CMSSection'
import { Text } from 'src/components/Text/Text'

import type { Contract } from '../../types'

import styles from './MemberDashboard.module.css'
import { Link } from 'react-router-dom'
import { routes } from 'src/routes'


type Sorting = {
  field: keyof Contract
  descending: boolean
}


type HeadProps = {
  label: string
  field?: keyof Contract
  sorting?: Sorting | null
}

function Head(props: HeadProps) {
  const {label} = props

  return (
    <th className={styles.th}>
      <div className={styles.head}>
        <Text className={styles.label}>{label}</Text>
      </div>
    </th>
  )
}


function MemberDashboard() {
  const context = useAppContext()

  console.log('contracts', context.contracts)

  return (
    <BasePage>
      <Breadcrumb/>

      <CMSSection
        primary={
          <>
            <SectionTitle title={`Welcome ${context.user?.name}`} size={SectionTitleSize.Small} style={SectionTitleStyle.Dark} />

            <Text>Your contracts</Text>
            <table className={styles.table}>
              <thead>
                <tr>
                  <Head
                    field={'name'}
                    label={'Name'}
                  />
                  <Head
                    field={'siteStartDate'}
                    label={'Site Start Date'}
                  />
                  <Head
                    field={'endDate'}
                    label={'Projected End Date'}
                  />
                  <Head
                    field={'submissions'}
                    label={'Last Submission Date'}
                  />
                  {/* <Head
                    field={'dueNext'}
                    label={'Report Due'}
                  /> */}
                  <Head
                    label={'Action'}
                  />
                </tr>
              </thead>
              <tbody>
                {context.contracts.map(c => {
                  // const url = c.programmeValid === false ?
                  //   formatURL(routes.member.children!.programme.path, {contract: c._id})
                  //   :
                  //   c.openReport?._id ?
                  //     formatURL(routes.member.children!.reportContinue.path, {contract: c._id, submission: c.openReport._id})
                  //     :
                  //     formatURL(routes.member.children!.report.path, {contract: c._id})
                  const url = formatURL(routes.member.children!.report.path, {contract: c._id })
                  const programUrl = formatURL(routes.member.children!.programme.path, { contract: c._id })
                  
                  //const url = c.openReport?._id ? formatURL(routes.member.children!.reportContinue.path, {contract: c._id, submission: c.openReport._id}) : formatURL(routes.member.children!.report.path, {contract: c._id})

                  const lastSubmission = c.contractLastReport
                  
                  const periods = c.periods.filter(p => {
                    if (c.submissions.find(s => s.period === p && s.submitted) !== undefined) 
                      return false
                
                    // return (new Date()).getTime() > (new Date(`${p}-${contract.reportDueDay}`)).getTime()
                    return true
                  }) ?? []
                  
                  return (
                    <tr key={c._id}>
                      <td className={styles.cell}><Text className={styles.text}>{c.name}</Text></td>
                      <td className={styles.cell}><Text className={styles.text}>{formatDate(c.siteStartDate, {hour: undefined, minute: undefined})}</Text></td>
                      <td className={styles.cell}><Text className={styles.text}>{formatDate(c.endDate, {hour: undefined, minute: undefined})}</Text></td>
                      <td className={styles.cell}><Text className={styles.text}>{lastSubmission ? formatDate(new Date(lastSubmission.createdAt), {hour: undefined, minute: undefined}) : ''}</Text></td>
                      {/* <td className={styles.cell}><Text className={styles.text}>{formatDate(c.dueNext, {hour: undefined, minute: undefined})}</Text></td> */}
                      <td className={styles.cell}>
                        {periods.length === 0 ? null :
                          <Link className={styles.button} to={url}>
                            {
                              'Submit Report' // c.programmeValid === false ? 'Submit Programme' : c.openReport ? 'Continue Report' : 'Submit Report'
                            }
                          </Link>
                        }
                        {c.useProgramme ? (
                        <Link className={styles.button} to={programUrl}>
                          Update Programme
                        </Link>) : null}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        }
        background={CMSSectionBackground.White}
      />
    </BasePage>
  )
}

export { MemberDashboard }


