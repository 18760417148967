import { useState } from 'react'
import { BACKEND_URL } from 'src/util'
import { ReactComponent as Play } from '../../images/play.svg'
import styles from './SectionGuide.module.css'

interface Card {
  title: string
  file: string
}

type Props = {
  cards: Card[]
}

function GuideCard({ guide }: { guide: Card }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  return (
    <div className={styles.guide}>
      <video className={styles.image}>
        <source src={`${BACKEND_URL}/file/${guide.file}`} type='video/webm' />
        <source src={`${BACKEND_URL}/file/${guide.file}`} type='video/mp4' />
      </video>
      <div className={styles.overlay} onClick={() => setIsPopupOpen(true)}>
        <Play className={styles.play} />
        <div className={styles.name}>{guide.title}</div>
      </div>
      {isPopupOpen && (
        <div className={styles.popup} onClick={() => setIsPopupOpen(false)}>
          <video
            className={styles.popupVideo}
            autoPlay
            controls
            controlsList='nodownload nofullscreen noremoteplayback'
            disablePictureInPicture
            onClick={(e) => e.stopPropagation()}
          >
            <source
              src={`${BACKEND_URL}/file/${guide.file}`}
              type='video/webm'
            />
            <source
              src={`${BACKEND_URL}/file/${guide.file}`}
              type='video/mp4'
            />
          </video>
        </div>
      )}
    </div>
  )
}

function SectionGuide({ cards }: Props) {
  return (
    <div className={styles.guides}>
      {cards.map((card, idx) => (
        <GuideCard guide={card} key={idx} />
      ))}
    </div>
  )
}

export { SectionGuide }
export type { Card as GuideCard }
