import { PageTitle } from '../components/PageTitle/PageTitle'
import { CMSSection, CMSSectionBackground } from '../components/CMSSection/CMSSection'
import { SectionText } from '../components/Text/Text'
import { SectionLink } from '../components/SectionLink/SectionLink'
import { BasePage } from './BasePage/BasePage'
import { SectionTitle, SectionTitleSize, SectionTitleStyle } from '../components/SectionTitle/SectionTitle'

function NotFound() {
  return (
    <BasePage
      title={<PageTitle title={'404'} subtitle={'NOT FOUND'}/>}
    >
      <CMSSection
        background={CMSSectionBackground.White}
        secondary={<img src={'/images/house.png'}/>}
        primary={
          <>
            <SectionTitle title={'404 - NOT FOUND'} size={SectionTitleSize.Small} style={SectionTitleStyle.Dark}/>
            <SectionText>The page you were looking for does not exist.</SectionText>
            <SectionLink label='HOME' path={'/'}/>
          </>
        }
      />
    </BasePage>
  )
}

export { NotFound }


