import React, { createContext, useContext } from 'react'
import { WizardData } from './components/MultiForm/MultiForm'
import { WizardResult } from './components/WizardResultCard/WizardResultCard'
import { JSONObject, MenuSerialised, PageSerialised } from './util'
import type { Contract } from './types'

type SetContext = React.Dispatch<React.SetStateAction<ContextValue>>
type ContextValue = {
  // have we loaded data from the backend
  loaded: boolean,

  menus: {[name: string]: MenuSerialised}
  pages: {[name: string]: PageSerialised}

  user: JSONObject | null

  // wizard stuff
  wizard: WizardData
  results: WizardResult[]
  selection: WizardResult | null


  // contract stuff
  contracts: Contract[]

  setContext: SetContext
}

type AppProviderProps = {
  context: ContextValue
  children: React.ReactNode
}

const AppContext = createContext({})

const AppContextProvider = ({ context, children }: AppProviderProps) => {
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}

const useAppContext = (): ContextValue => {
  const context = useContext(AppContext)
  if (!context)
    throw new Error('You must use AppContextProvider to consume this context.')
  return (context as ContextValue)
}

export { AppContextProvider, useAppContext }
export type { SetContext, ContextValue }
