import { Link } from 'react-router-dom'
import { Text } from '../Text/Text'
import { routes } from '../../routes'
import { ReactComponent as Phone } from '../../images/phone.svg'
import styles from './FooterCallback.module.css'

function FooterCallback(props: { telephone: string }) {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <Text className={styles.title}>LET'S TALK ABOUT YOUR NEEDS</Text>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.right}>
          <a
            className={styles.phone}
            href={`tel:${props.telephone.replaceAll(' ', '')}`}
          >
            <Phone className={styles.phoneIcon} />
            <Text className={styles.phoneText}>{props.telephone}</Text>
          </a>
          <Link className={styles.button} to={routes.contact.path}>
            <div className={styles.label}>BOOK A CALLBACK</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export { FooterCallback }
