import { NavLink } from 'react-router-dom'

import footer from '../../images/footer.png'
import footer2x from '../../images/footer@2x.png'

import type { FooterProps } from './Footer'

import styles from './FooterNavigation.module.css'
import { useAppContext } from 'src/Context'

function FooterNavigation(props: FooterProps) {
  const context = useAppContext()
  const footerNav = context.menus['footer']?.items ?? []
  const legalNav = context.menus['legal']?.items ?? []

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div>
          <div className={styles.content}>
            <img className={styles.logo} src={footer} srcSet={`${footer2x} 2x`} alt="" />
            {props.address?.map(line => <div key={line} className={styles.address}>{line}</div>)}
            <a className={styles.telephone} href={`tel:${props.telephone?.replaceAll(' ', '')}`}>TEL: {props.telephone}</a>
            <a className={styles.email} href={`mailto:${props.email}`}>EMAIL: {props.email}</a>
          </div>
        </div>

        <div>
          <div className={styles.content}>
            <h4 className={styles.title}>QUICK LINKS</h4>
            {footerNav.map(item => {
              if (item.path === undefined)
                return null
              return <NavLink key={item.name} to={item.path} className={styles.item}>{item.name}</NavLink>
            })}
          </div>
        </div>

        <div>
          <div className={styles.content}>
            <h4 className={styles.title}>LEGAL</h4>
            {legalNav.map(item => {
              if (item.path === undefined)
                return
              return <NavLink key={item.name} to={item.path} className={styles.item}>{item.name}</NavLink>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export { FooterNavigation }
