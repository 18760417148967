import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Contract } from 'src/types'
import { useAppContext } from '../../Context'
import { UserPage } from '../../pages/UserPage/UserPage'
import { routes } from '../../routes'
import { API_URL, BACKEND_URL, JSONObject } from '../../util'
import { Captcha } from '../Captcha/Captcha'
import {
  SectionTitle,
  SectionTitleSize,
  SectionTitleStyle
} from '../SectionTitle/SectionTitle'
import { Text } from '../Text/Text'
import styles from './Login.module.css'

function Login() {
  const initialData = {
    username: '',
    password: '',
    captcha: ''
  }
  const [state, setState] = useState(initialData)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<
    { field: string | null; message: string } | undefined
  >(undefined)
  const navigate = useNavigate()
  const context = useAppContext()
  const [grecaptcha, setGrecaptcha] = useState<ReCaptchaV2.ReCaptcha>()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError(undefined)
    setSubmitting(true)

    try {
      const response = await fetch(`${BACKEND_URL}login`, {
        method: 'post',
        body: JSON.stringify(state)
      })
      setSubmitting(false)
      grecaptcha?.reset()

      if (response.ok === false) {
        setError({ field: null, message: response.statusText })
        return
      }

      const json: JSONObject = await response.json()

      if ((json.success as boolean) === false) {
        setError({
          field: json.field as string | null,
          message: json.error as string
        })
        return
      }

      const user = json.user as JSONObject

      localStorage.setItem('user', JSON.stringify(json.user))

      const contractFetch = await fetch(`${API_URL}contracts`, {
        method: 'post',
        headers: {
          Authorization: `Token ${user.token}`
        },
        body: new URLSearchParams([['username', user.username as string]])
      })

      const contractData = contractFetch.ok ? (await contractFetch.json() as JSONObject[]) : context.contracts
      const contracts = contractData.map(c => {
        return {
          ...c,
          siteStartDate: new Date(c.siteStartDate as string),
          endDate: new Date(c.endDate as string),
          reportStartDate: new Date(c.reportStartDate as string),
          dueNext: new Date(c.dueNext as string),
          createdAt: new Date(c.createdAt as string)
        } as Contract
      })

      context.setContext({ ...context, user: user, contracts })

      navigate('/member')
    } catch (error) {
      setError({ field: null, message: (error as Error).message })
      setSubmitting(false)
      grecaptcha?.reset()
      return
    }
  }

  return (
    <UserPage>
      <div className={styles.container}>
        <SectionTitle
          size={SectionTitleSize.Small}
          style={SectionTitleStyle.Dark}
          title={'LOGIN'}
        />
        <div className={styles.subtitle}>
          Get more things done by logging into our platform.
        </div>
        {error && error.field === null ? (
          <Text className={styles.error}>{error.message}</Text>
        ) : null}

        <div className={styles.form_bottom}>
          <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles.fields}>
              <div className={styles.field}>
                <label
                  className={`${styles.label} ${
                    error && error.field === 'username' ? styles.error : ''
                  }`}
                  htmlFor={'username'}
                >
                  Username
                </label>
                <input
                  className={styles.input}
                  type={'text'}
                  name={'username'}
                  value={state.username}
                  onChange={onChange}
                />
                {error && error.field === 'username' ? (
                  <Text className={styles.error}>{error.message}</Text>
                ) : null}
              </div>
              <div className={styles.field}>
                <label
                  className={`${styles.label} ${
                    error && error.field === 'password' ? styles.error : ''
                  }`}
                  htmlFor={'password'}
                >
                  Password
                </label>
                <input
                  className={styles.input}
                  type={'password'}
                  name={'password'}
                  value={state.password}
                  onChange={onChange}
                />
                {error && error.field === 'password' ? (
                  <Text className={styles.error}>{error.message}</Text>
                ) : null}
              </div>
              <Captcha
                onChange={(token) =>
                  setState((oldData) => ({ ...oldData, captcha: token }))
                }
                setGrecaptcha={setGrecaptcha}
              />
              {error && error.field === 'captcha' ? (
                <Text className={styles.error}>{error.message}</Text>
              ) : null}
              <div className={styles.submitAndPassword}>
                <button className={styles.submit} disabled={submitting}>
                  LOGIN
                </button>
                <Link
                  className={styles.forgot}
                  to={routes.forgottenPassword.path}
                >
                  Forgotten Password?
                </Link>
              </div>
              <div className={styles.join}>
                Not a member?{' '}
                <Link className={styles.link} to={routes.register.path}>
                  Sign up now
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </UserPage>
  )
}

export { Login }
