import { ContactUsPage } from './pages/ContactUsPage'
import { Login } from './components/Login/Login'
import { Register } from './components/Register/Register'
import { WizardPage } from './pages/WizardPage'
import { SignupSuccess } from './pages/SignupSuccess/SignupSuccess'
import { WizardChosenResults } from './components/WizardChosenResults/WizardChosenResults'
import { BlogPage } from './pages/BlogPage'
import { ForgottenPassword } from './components/ForgottenPassword/ForgottenPassword'
import { PasswordReset } from './components/PasswordReset/PasswordReset'
import { VerifyEmail } from './components/VerifyEmail/VerifyEmail'
import { PageSerialised } from './util'
import { DynamicPage } from './pages/DynamicPage'
import { ContextValue } from './Context'
import { BlogPost } from './components/BlogPost/BlogPost'
import { MemberDashboard } from './pages/MemberDashboard/MemberDashboard'
import { ContractReport } from './pages/ContractReport/ContractReport'
import { ContractProgramme } from './pages/ContractProgramme/ContractProgramme'

type Routing = {
  slug: string // slug is like the page name
  path: string // absolute path
  page: React.ReactNode
  title: string
  metatitle?: string
  metadescription?: string
  children?: { [key: string]: Routing }
}

/**
 * This object defines the structure of the website, all routes that are accessible must be in here
 * the structure is also heirarchical, so subpages should be grouped under the children key
 */
const routes: { [key: string]: Routing } = {
  contact: {
    slug: 'contact-us',
    path: '/contact-us',
    page: <ContactUsPage />,
    title: 'CONTACT US'
  },
  blog: {
    slug: 'blog',
    path: '/blog',
    page: <BlogPage />,
    title: 'OUR BLOG',
    children: {
      post: {
        slug: 'post',
        path: '/blog/:slug',
        page: <BlogPost/>,
        title: 'BLOG POST'
      }
    }
  },

  // wizard
  wizard: {
    slug: 'wizard',
    path: '/wizard',
    page: <WizardPage />,
    title: 'WIZARD',
    children: {
      summary: {
        slug: 'summary',
        path: '/wizard/summary',
        page: <WizardChosenResults />,
        title: 'WIZARD SUMMARY'
      }
    }
  },

  // user
  register: {
    slug: 'register',
    path: '/register',
    page: <Register/>,
    title: 'REGISTER'
  },
  login: {
    slug: 'login',
    path: '/login',
    page: <Login/>,
    title: 'LOGIN'
  },
  success: {
    slug: 'success',
    path: '/success',
    page: <SignupSuccess/>,
    title: 'SUCCESS'
  },

  verifyEmail: {
    slug: 'verify-email',
    path: '/verify-email',
    page: <VerifyEmail/>,
    title: 'VERIFY EMAIL'
  },

  forgottenPassword: {
    slug: 'forgotten-password',
    path: '/forgotten-password',
    page: <ForgottenPassword/>,
    title: 'FORGOTTEN PASSWORD'
  },
  passwordReset: {
    slug: 'password-reset',
    path: '/password-reset',
    page: <PasswordReset/>,
    title: 'PASSWORD RESET'
  },


  // member area
  member: {
    slug: 'member',
    path: '/member',
    page: <MemberDashboard/>,
    title: 'DASHBOARD',
    children: {
      programme: {
        slug: 'programme',
        path: '/member/programme/:contract',
        page: <ContractProgramme/>,
        title: 'CONTRACT PROGRAMME'
      },
      report: {
        slug: 'report',
        path: '/member/report/:contract',
        page: <ContractReport/>,
        title: 'CONTRACT REPORT'
      },
      reportContinue: {
        slug: 'report',
        path: '/member/report/:contract/:submission',
        page: <ContractReport/>,
        title: 'CONTRACT REPORT'
      }
    }
  }
}

const buildPath = (page: PageSerialised | undefined, pages: {[slug: string]: PageSerialised}) => {
  if (page === undefined)
    throw new Error('Called `findPath` on undefined page')

  if (page.slug === 'homepage')
    return '/'

  const steps = [page.slug]

  while (page && page.parentId) {
    page = Object.values(pages).find((p) => p._id === page?.parentId)
    if (page)
      steps.unshift(page.slug)
  }

  return '/' + steps.join('/')
}

function buildTreeInner(page: PageSerialised, context: Omit<ContextValue, 'setContext'>): Routing {
  const children = Object.values(context.pages).filter(p => p.parentId === page._id)

  return {
    slug: page.slug,
    path: buildPath(page, context.pages),
    page: <DynamicPage title={page.title} pageId={page._id}/>,
    title: page.title,
    children: children.reduce((obj, p) => ({...obj, [p.slug]: buildTreeInner(p, context)}), {}),
    metatitle: page.metatitle,
    metadescription: page.metadescription
  }
}

function buildTree(routes: { [key: string]: Routing }, context: Omit<ContextValue, 'setContext'>) {
  const tree = {...routes}

  for (const page of Object.values(context.pages).filter(p => p.parentId === '')) {
    tree[page.slug] = buildTreeInner(page, context)
  }
  return tree
}

export { routes, buildPath, buildTree }
export type { Routing }
