import React, { useState } from 'react'
import { BACKEND_URL } from 'src/util'
import { Captcha } from '../Captcha/Captcha'
import { InputField } from '../InputField/InputField'
import { Notification } from '../Notification/Notification'
import {
  SectionTitle,
  SectionTitleStyle,
  SectionTitleSize
} from '../SectionTitle/SectionTitle'
import { Select } from '../Select/Select'
import { TextArea } from '../TextArea/TextArea'

import styles from './ContactUs.module.css'

function ContactUs() {
  const initialData = {
    name: '',
    email: '',
    telephone: '',
    time: '',
    subject: '',
    message: '',
    captcha: ''
  }
  const [data, setData] = useState<{
    name: string
    email: string
    telephone: string
    time: string
    subject: string
    message: string
    captcha: string
  }>(initialData)

  const [response, setResponse] = useState<{
    success: boolean
    message: string
  } | null>(null)
  const [grecaptcha, setGrecaptcha] = useState<ReCaptchaV2.ReCaptcha>()

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    fetch(`${BACKEND_URL}callback`, {
      method: 'post',
      body: new URLSearchParams(data)
    })
      .then((res) => {
        console.log(res)
        return res.text()
      })
      .then((result) => {
        const res = JSON.parse(result)
        setResponse(res)
        if (res.success) setData(initialData)
      })
      .catch(() => {
        setResponse({
          success: false,
          message: 'Something went wrong. Please try again later.'
        })
      })
      .finally(() => {
        if (grecaptcha) grecaptcha.reset()
      })
  }

  const notification = response ? <Notification response={response} /> : ''
  const times = [
    {value: '', label: 'Best time to call you', disabled: true},
    {value: 'morning', label: 'Morning'},
    {value: 'afternoon', label: 'Afternoon'},
    {value: 'evening', label: 'Evening'},
    {value: 'any', label: 'No preference'},
  ]

  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.innerBodyWrapper}>
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.overlay}>
              <div className={styles.contentWrapper}>
                <div className={styles.info}>
                  <SectionTitle
                    size={SectionTitleSize.Medium}
                    style={SectionTitleStyle.Light}
                    title={'CONTACT US'}
                  />
                  <span className={styles.text}>
                    Interested in our services?
                  </span>
                  <span className={styles.text}>
                    To find out more please call us on{' '}
                    <a href={`tel:${'01508488244'}`} className={styles.link}>
                      01508 488244
                    </a>
                    , email us at{' '}
                    <a
                      href={`mailto:${'info@eastern-procurement.co.uk'}`}
                      className={styles.link}
                    >
                      info@eastern-procurement.co.uk
                    </a>{' '}
                    or fill in the form and one of team will be in touch.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <form method={'post'} className={styles.form} onSubmit={onSubmit}>
            {notification}
            <label className={styles.label}>
              Your Name<span className={styles.required}>*</span>
              <InputField
                type={'text'}
                required={true}
                value={data.name}
                onChange={e => setData({ ...data, name: e.target.value })}
              />
            </label>
            <label className={styles.label}>
              Your Email<span className={styles.required}>*</span>
              <InputField
                type={'email'}
                required={true}
                value={data.email}
                onChange={e => setData({ ...data, email: e.target.value })}
              />
            </label>
            <label className={styles.label}>
              Phone<span className={styles.required}>*</span>
              <InputField
                type={'tel'}
                required={true}
                value={data.telephone}
                onChange={e => setData({ ...data, telephone: e.target.value })}
              />
            </label>
            <label className={styles.label}>
              Best time to call you<span className={styles.required}>*</span>
              <Select
                value={data.time}
                onChange={e => setData({ ...data, time: e.target.value })}
                required={true}
                options={times}
                sort={false}
              />
            </label>
            <label className={styles.label}>
              Enquiry subject<span className={styles.required}>*</span>
              <Select
                value={data.subject}
                onChange={e => setData({...data, subject: e.target.value})}
                required={true}
                options={[
                  {value: '', label: 'Subject', disabled: true},
                  {value: 'Complaint', label: 'Performance Issue / Complaint'},
                  {value: 'New Business', label: 'New Business / Benchmark'},
                  {value: 'Other', label: 'Other'}
                ]}
                sort={false}
              />
            </label>
            <label className={styles.label}>
              Your Message<span className={styles.required}>*</span>
              <TextArea
                className={styles.textarea}
                required={true}
                value={data.message}
                onChange={e => setData({ ...data, message: e.target.value })}
              />
            </label>
            <div className={styles.captchaWrapper}>
              <Captcha
                onChange={token => setData(oldData => ({ ...oldData, captcha: token }))}
                setGrecaptcha={setGrecaptcha}
              />
            </div>
            {notification}
            <input
              className={styles.submit}
              type={'submit'}
              value={'SEND MESSAGE'}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export { ContactUs }
