import { Link, NavLink, useNavigate } from 'react-router-dom'
import { routes } from '../../routes'
import type { Routing } from '../../routes'
import { BACKEND_URL, MenuItem, toLocaleTitleCase } from '../../util'
import styles from './Header.module.css'

import { ReactComponent as Register } from '../../images/register.svg'
import { ReactComponent as Login } from '../../images/login.svg'

// import logo from '../../images/logo.png'
import logo2x from '../../images/logo@2x.png'
import { useState } from 'react'
import { HeaderCallBackCTA } from './HeaderCallBackCTA'
import { MobileHamburgerMenu } from '../MobileHamburgerMenu/MobileHamburgerMenu'
import { useAppContext } from '../../Context'
import { Text } from '../Text/Text'
import { CaretDown } from 'react-ionicons'
import { Close } from 'react-ionicons'

function HeaderButton({
  icon,
  route
}: {
  icon: React.ReactNode
  route: Routing
}) {
  return (
    <div className={styles.button}>
      {icon}
      <Link to={route.path} className={styles.label}>
        {route.title}
      </Link>
    </div>
  )
}

function NavItem({ item }: { item: MenuItem }) {
  const [expand, setExpand] = useState(false)

  if (item.path === undefined) return null

  return (
    <div
      className={styles.item}
      onMouseOver={() => setExpand(item.items.length > 0)}
      onMouseLeave={() => setExpand(false)}
    >
      <NavLink
        to={item.path}
        className={({ isActive }) =>
          `${styles.link} ${isActive ? styles.active : ''}`
        }
      >
        {item.name}
      </NavLink>
      {expand ? (
        <div className={styles.subnav}>
          {item.items.map((i) => {
            if (i.path === undefined) return null
            return (
              <NavLink
                key={i.path}
                to={i.path}
                className={({ isActive }) =>
                  `${styles.subitem} ${isActive ? styles.active : ''}`
                }
              >
                {toLocaleTitleCase(i.name)}
              </NavLink>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

function NavMobileItem({ item }: { item: MenuItem }) {
  if (item.path === undefined) return null

  return (
    <div className={styles.item}>
      <NavLink
        to={item.path}
        className={({ isActive }) =>
          `${styles.link} ${styles.mobileLink} ${isActive ? styles.active : ''}`
        }
      >
        {item.name}
      </NavLink>
      {item.items.length ? (
        <>
          {item.items.map((i) => {
            if (!i.path) return null
            return (
              <NavLink
                key={i.path}
                to={i.path}
                className={({ isActive }) =>
                  `${styles.mobileSubitem} ${isActive ? styles.active : ''}`
                }
              >
                {toLocaleTitleCase(i.name)}
              </NavLink>
            )
          })}
        </>
      ) : null}
    </div>
  )
}

function Header() {
  const context = useAppContext()
  const [showDropdown, setShowDropdown] = useState(false)
  const [showMobileNav, setShowMobileNav] = useState(false)
  const navigate = useNavigate()

  const logout = () => {
    if (context.user === null) return

    fetch(`${BACKEND_URL}logout`, {
      method: 'post',
      headers: {
        Authorization: `Token ${context.user.token}`
      }
    })
    context.setContext({ ...context, user: null, contracts: [] })
    localStorage.removeItem('user')
    navigate('/')
  }

  const mainNav = context.menus['main']?.items ?? []

  return (
    <>
      <div
        className={`${styles.mobileNavigation} ${
          showMobileNav ? styles.mobileNavigationActive : undefined
        }`}
      >
        <div
          className={styles.mobileNavigationClose}
          onClick={() => setShowMobileNav(false)}
        >
          <Close color={'#fff'} height={'36px'} width={'36px'} />
        </div>

        <div className={styles.mobileNavigationItems}>
          {mainNav.map((item) => {
            return <NavMobileItem key={item.name} item={item} />
          })}
        </div>
        <Link to={routes.contact.path} className={styles.ctaMobile}>
          <HeaderCallBackCTA />
        </Link>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.top}>
          {context.user ? (
            <div
              className={styles.user}
              onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget))
                  setShowDropdown(false)
              }}
              tabIndex={-1}
            >
              <div
                className={styles.userButton}
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <Text className={styles.username}>
                  Hello {context.user.name ?? context.user.username}
                </Text>
                <CaretDown cssClasses={styles.arrow} color={'#fff'} />
              </div>
              {showDropdown ? (
                <div className={styles.dropdown}>
                  <Link className={styles.dropdownLink} to={routes.member.path}>
                    <Text className={styles.item}>Dashboard</Text>
                  </Link>
                  <div onClick={logout}>
                    <Text className={styles.item}>Log out</Text>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <>
              <HeaderButton icon={<Register />} route={routes.register} />
              <HeaderButton icon={<Login />} route={routes.login} />
            </>
          )}
        </div>
        <div className={styles.bottom}>
          <Link to={'/'} className={styles.logoLink}>
            <img src={logo2x} srcSet={`${logo2x} 2x`} className={styles.logo} />
          </Link>
          <div className={styles.navigation}>
            {mainNav.map((item) => {
              return <NavItem key={item.name} item={item} />
            })}
          </div>
          <div className={styles.rightNavContainer}>
            <Link to={routes.contact.path} className={styles.ctaContainer}>
              <HeaderCallBackCTA />
            </Link>
            <MobileHamburgerMenu onClick={() => setShowMobileNav(true)} />
          </div>
        </div>
      </div>
      <div className={styles.spacer}></div>
    </>
  )
}

export { Header }
