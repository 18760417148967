import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { ContextValue, useAppContext } from 'src/Context'
import { routes } from 'src/routes'
import { API_URL } from 'src/util'
import { Button } from 'src/wizard/Button/Button'
import {
  SectionTitle,
  SectionTitleSize,
  SectionTitleStyle
} from '../SectionTitle/SectionTitle'
import {
  WizardResultCard,
  WizardResult
} from '../WizardResultCard/WizardResultCard'

import styles from './ContractorsResult.module.css'

type ContractorsResultType = {
  other: () => void

  submission: string | null
  // wizard: WizardData
  results: WizardResult[]
}

function goAhead(
  navigate: NavigateFunction,
  context: ContextValue,
  submission: string,
  result: WizardResult
) {
  if (context.user === null) return

  const data = new URLSearchParams()
  data.append('username', context.user.username as string)
  data.append('submission', submission)
  data.append('contractor', result.contractorId)
  data.append('service', result.service)
  data.append('specification', JSON.stringify(result.specification))

  fetch(`${API_URL}wizard/select`, {
    method: 'post',
    headers: {
      Authorization: `Token ${context.user.token}`
    },
    body: data
  })

  context.setContext({ ...context, selection: result })

  if (routes.wizard.children) navigate(routes.wizard.children.summary.path)
}

function email(
  context: ContextValue,
  submission: string,
  setSentSummary: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (context.user === null) return

  const data = new URLSearchParams()
  data.append('username', context.user.username as string)
  data.append('submission', submission)

  fetch(`${API_URL}wizard/summary`, {
    method: 'post',
    headers: {
      Authorization: `Token ${context.user.token}`
    },
    body: data
  }).then(() => {
    setSentSummary(true)
  })
}

function ContractorsResult(props: ContractorsResultType) {
  const context = useAppContext()
  const navigate = useNavigate()
  const [sentSummary, setSentSummary] = useState(false)

  return (
    <div>
      <div className={styles.container}>
        <SectionTitle
          title={'CONTRACTORS'}
          size={SectionTitleSize.Small}
          style={SectionTitleStyle.LightBlue}
        />
        <div className={styles.row}>
          <div className={styles.count}>
            <span className={styles.total}>{props?.results?.length ?? 0}</span>{' '}
            CONTRACTOR{props?.results?.length > 1 ? 'S' : ''} FOUND
          </div>
          {props?.results?.length > 0 ? (
            <Button
              label={sentSummary ? 'Summary sent' : 'Email Summary'}
              disabled={sentSummary}
              onClick={(event) => {
                event.preventDefault()
                email(context, props.submission as string, setSentSummary)
              }}
            />
          ) : null}
        </div>

        <div>
          {props?.results
            ?.sort((a, b) => a.amount - b.amount)
            .map((result, index) => {
              return (
                <WizardResultCard
                  key={index}
                  {...result}
                  position={index + 1}
                  goAhead={() =>
                    goAhead(
                      navigate,
                      context,
                      props.submission as string,
                      result
                    )
                  }
                  other={
                    context.wizard.route === 'contractor'
                      ? props.other
                      : undefined
                  }
                />
              )
            })}
        </div>
      </div>
    </div>
  )
}
export { ContractorsResult }
