import styles from './Progress.module.css'

type ProgressProps = {
  value: number
  max: number
  trackClass?: string
  trackStyle?: React.CSSProperties
  barClass?: string
  barStyle?: React.CSSProperties
}

function Progress(props: ProgressProps) {
  const width = props.value / props.max * 100
  return (
    <div className={`${styles.track} ${props.trackClass ?? null}`} style={props.trackStyle}>
      <div className={`${styles.bar} ${styles.barClass}`} style={{...props.barStyle, width: `${width}%`}}></div>
    </div>
  )
}

export { Progress }
export type { ProgressProps }
