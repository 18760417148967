import { Link } from 'react-router-dom'
import { routes } from '../../routes'

import styles from './Hero.module.css'

import { ReactComponent as UsingOurPortal } from '../../images/using-our-portal.svg'
import { ReactComponent as UsingOurPortalArrow } from '../../images/using-our-portal-arrow.svg'
import { ReactComponent as Portal } from '../../images/portal.svg'
import { ReactComponent as Scroll } from '../../images/scroll.svg'
import { Text } from '../Text/Text'

import poster from '../../images/photos/hero.jpeg'

function HeroVideo() {
  return (
    <video className={styles.video} autoPlay loop muted poster={poster}>
      <source src={'https://eastern-procurement-staging.fra1.digitaloceanspaces.com/ep-header-v2.webm'} type={'video/webm'} />
      <source src={'https://eastern-procurement-staging.fra1.digitaloceanspaces.com/ep-header-v2.mp4'} type={'video/mp4'} />
    </video>
  )
}

type HeroProps = {
  title: string
  subtitle: string
  content: string
}

function Hero(props: HeroProps) {
  return (
    <div className={styles.section}>
      <HeroVideo />
      <div className={styles.overlay}>
        <div className={styles.heroWrapper}>
          <div className={styles.container}>
            <div className={styles.header}>
              <span className={styles.tagline}>{props.subtitle}</span>
              <h1 className={styles.title}>{props.title}</h1>
            </div>
            <Text className={styles.text}>Established in 2008 EP drives performance, quality and efficiency for its customer organisations. Not for Profit and owned by social housing providers across the East of England, we specialise in making sustainable, high-performing publicly procured solutions readily available and easily accessible</Text>
            <Text className={styles.text}>Committed to transparency and ease of access, we have introduced the first social housing services online benchmarking tool. Access is available to all social housing customer organisations</Text>
            <div className={styles.ctaPortalWrapper}>
              <div className={styles.ctaWrapper}>
                <Link className={styles.cta} to={routes.wizard.path}>BENCHMARK &amp; COMMISSION</Link>
              </div>
              <div className={styles.portalWrapper}>
                <Link className={styles.portal} to={routes.wizard.path}>
                  <UsingOurPortal className={styles.portalText}/>
                  <UsingOurPortalArrow className={styles.portalArrow}/>
                  <Portal className={styles.portalButton}/>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.scrollWrapper}>
            <Scroll className={styles.scroll}/> 
          </div>
        </div>
      </div>
    </div>
  )
}

export { Hero }

