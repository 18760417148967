import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useAppContext } from 'src/Context'
import { UserPage } from 'src/pages/UserPage/UserPage'
import { routes } from 'src/routes'
import { BACKEND_URL, JSONObject } from 'src/util'
import { PasswordStrength } from '../PasswordStrength/PasswordStrength'
import { SectionTitle, SectionTitleSize, SectionTitleStyle } from '../SectionTitle/SectionTitle'
import { Text } from '../Text/Text'
import styles from './PasswordReset.module.css'

function PasswordReset() {
  const context = useAppContext()
  const [state, setState] = useState<{[field: string]: string}>({})
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<{field: string | null, message: string} | undefined>(undefined)
  const [tokenError, setTokenError] = useState<{field: string | null, message: string} | undefined>(undefined)
  const [searchParams] = useSearchParams()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({...state, [event.target.name]: event.target.value})
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError(undefined)

    for (const field of (event.target as HTMLFormElement).elements) {
      if ((field as HTMLInputElement).reportValidity() === false)
        return
    }

    (async () => {
      setSubmitting(true)

      const fd = new FormData(event.target as HTMLFormElement)
      const data: {[field: string]: string} = {'token': searchParams.get('token') as string}
      fd.forEach((v, k) => data[k] = v as string)

      try {
        const response = await fetch((event.target as HTMLFormElement).action, {
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams(data)
        })
        setSubmitting(false)

        if (response.ok === false) {
          setError({field: null, message: response.statusText})
          return
        }

        const json: JSONObject = await response.json()
        if (json.success as boolean === false) {
          setError({field: json.field as string | null, message: json.error as string})
          return
        }

        // log them in for them
        if (data.remember)
          localStorage.setItem('user', JSON.stringify(json.user))

        context.setContext({...context, user: json.user as JSONObject})
      }
      catch (error) {
        setError({field: null, message: (error as Error).message})
        setSubmitting(false)
        return
      }
    })()
  }

  useEffect(() => {
    const token = searchParams.get('token')

    if (!token)
      return

    (async () => {
      try {
        const response = await fetch(`${BACKEND_URL}verify-reset`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams([['token', token]])
        })

        if (response.ok === false) {
          setTokenError({field: null, message: response.statusText})
          return
        }

        const json: JSONObject = await response.json()
        if (json.success as boolean === false) {
          setTokenError({field: json.field as string | null, message: json.error as string})
          return
        }
      }
      catch (error) {
        setTokenError({field: null, message: (error as Error).message})
        return
      }
    })()
  }, [searchParams])

  return (
    <UserPage>
      <div className={styles.container}>
        <SectionTitle size={SectionTitleSize.Small} style={SectionTitleStyle.Dark} title={'FORGOTTEN PASSWORD'}/>
        <div className={styles.subtitle}>Enter your email address and we will send you a link to reset your password.</div>
        { error && error.field === null ? <Text className={styles.error}>{error.message}</Text> : null}
        { tokenError && tokenError.field === null ? <Text className={styles.error}>{tokenError.message}</Text> : null}

        <div className={styles.form_bottom}>
          { context.user ?
            <>
              <Text>Your password has been changed and you are now logged in.</Text>
              <div className={styles.join}>
                <Link className={styles.login} to={routes.home.path}>Return to Home</Link>
              </div>
            </>
            : tokenError ? null :
              <form action={`${BACKEND_URL}reset-password`} method={'POST'} className={styles.form} onSubmit={onSubmit}>
                <div className={styles.fields}>
                  <div className={styles.field}>
                    <label className={`${styles.label} ${error && error.field === 'password' ? styles.error : ''}`} htmlFor={'password'}>Password</label>
                    <input className={styles.input} type={'password'} name={'password'} onChange={onChange} required={true} value={state['password'] ?? ''} minLength={8}/>
                    { error && error.field === 'password' ?
                      <Text className={styles.error}>{error.message}</Text>
                      :
                      null
                    }
                  </div>
                  <div className={styles.field}>
                    <label className={`${styles.label} ${error && error.field === 'confirm' ? styles.error : ''}`} htmlFor={'confirm'}>Password</label>
                    <input className={styles.input} type={'password'} name={'confirm'} onChange={onChange} required={true} value={state['confirm'] ?? ''} minLength={8}/>
                    { error && error.field === 'confirm' ?
                      <Text className={styles.error}>{error.message}</Text>
                      :
                      null
                    }
                  </div>
                  <div className={styles.field}>
                    <PasswordStrength password={state['password'] ?? ''}/>
                  </div>

                  <div className={styles.submitAndPassword}>
                    <button className={styles.submit} disabled={submitting}>RESET PASSWORD</button>
                  </div>
                  <div className={styles.join}>
                    <Link className={styles.login} to={routes.login.path}>Return to Login</Link>
                  </div>
                </div>
              </form>
          }
        </div>
      </div>
    </UserPage>
  )
}

export { PasswordReset }


