import { FooterCallback } from './FooterCallback'
import { FooterNavigation } from './FooterNavigation'
import { FooterBottom } from './FooterBottom'

type FooterProps = {
  address?: string[]
  telephone?: string
  email?: string
  companyNumber?: string
}

function Footer(props: FooterProps) {
  const {
    address = ['THE OLD GRANARY', 'GRANGE FARM', 'ASHWELLTHORPE NORFOLK', 'NR16 1ET'],
    email = 'info@eastern-procurement.co.uk',
    telephone = '01508 488244',
    companyNumber = '08351985'
  } = props

  return (
    <>
      <FooterCallback telephone={telephone}/>
      <FooterNavigation address={address} email={email} telephone={telephone}/>
      <FooterBottom companyNumber={companyNumber}/>
    </>
  )
}

export { Footer }
export type { FooterProps }
