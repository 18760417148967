import { useFetch } from 'use-http'
import { BasePage } from './BasePage/BasePage'
import { Block } from '../components/Block/Block'
import type { BlockInstance } from '../components/Block/Block'
import { useAppContext } from '../Context'
import { API_URL } from '../util'

type PageProps = {
  title: string
  pageId: string
}

function DynamicPage(props: PageProps) {
  const context = useAppContext()

  let options: {
    headers: {
      'Content-Type': string
      Authorization?: string
    }
  } = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  if (context.user) {
    options.headers.Authorization = `Token ${context.user?.token}`
  }

  const { loading, data } = useFetch(
    `${API_URL}page/${props.pageId}`,
    options,
    [props.pageId]
  )

  if (loading) return null

  return (
    <BasePage>
      {(data?.content ?? []).map((instance: BlockInstance, index: number) => {
        return <Block key={index} block={instance} />
      })}
    </BasePage>
  )
}

export { DynamicPage }
