const formatCurrency = (
  value: number | undefined,
  options?: Intl.NumberFormatOptions
) => {
  if (!value) return ''
  const intl = new Intl.NumberFormat(undefined, {
    currency: 'GBP',
    ...(options ?? {}),
    style: 'currency'
  })
  return intl.format(value)
}

const formatDate = (value: Date, options?: Intl.DateTimeFormatOptions) => {
  return value.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    ...options
  })
}

const formatNumber = (
  value: number | undefined,
  options?: Intl.NumberFormatOptions
) => {
  if (!value && value !== 0) return ''
  const intl = new Intl.NumberFormat(undefined, { ...(options ?? {}) })
  return intl.format(value)
}


/**
 * Formats a URL for use with react-router-dom
 * @param  url:    A url string featuring `:<param>` sections
 * @param  params: Params to format into the URL
 * @return         the formatted URL
 */
function formatURL(url: string, params: {[key: string]: string}): string {
  let formatted = ''

  let index = 0
  while (index < url.length) {

    // if it's not a colon, just copy across
    if (url[index] !== ':') {
      formatted += url[index++]
      continue
    }

    // otherwise, keep going until we get to the end or a termination
    let end = ++index
    let optional = false
    while (end < url.length) {
      if (url[end] === '/')
        break
      if (url[end] === '?') {
        optional = true
        break
      }
      ++end
    }

    // we now have our key name, so extract it
    const name = url.substring(index, end)

    // if our key isn't optional but doesn't exist, throw
    if (optional === false && name in params === false)
      throw new Error(`Tried to replace ${name} but no value provided for it in params. Got: ${Object.keys(params).join(', ')}`)

    if (optional === false && (params[name] === null || params[name] === undefined))
      throw new Error(`Tried to replace ${name} but provided null/undefined in params`)

    // either optional is false, and we know params[name] is valid, or name is in params
    // so add it to our formatted string
    if (optional === false || (params[name] !== null && params[name] !== undefined))
      formatted += params[name]


    // and set the index one past the end, ready to parse the next character
    index = end
  }

  return formatted
}


function toLocaleTitleCase(string: string | undefined) {
  if (!string) return ''
  if (typeof string !== 'string') return ''

  return string
    .split(' ')
    .filter(word => word !== '')
    .map((word) => {
      if (word.length > 0) {
        return word[0].toLocaleUpperCase() + word.substring(1).toLocaleLowerCase()
      }
      return ''
    })
    .join(' ')
}




const BACKEND_URL = process.env.REACT_APP_STAGING ? 'https://ep-admin.cubane.org/' : (
  process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3010/' : 'https://admin.eastern-procurement.co.uk/'
)

const API_URL = `${BACKEND_URL}api/`

const CAPTCHA_SITEKEY = '6Len__sgAAAAAEjraWzHGMAJWvNG0wXdgzO6YsvC'

type JSONArray = JSONValue[]
type JSONObject = { [key: string]: JSONValue | null }
type JSONValue = string | number | boolean | JSONArray | JSONObject

// copied from backend
type MenuItem = {
  name: string
  path?: string

  items: MenuItem[]
}

type MenuSerialised = {
  name: string,
  items: MenuItem[]
}

type PageSerialised = {
  _id: string
  title: string
  parentId: string
  slug: string
  path: string
  metatitle?: string
  metadescription?: string
}

export {
  formatCurrency,
  formatDate,
  formatNumber,
  formatURL,
  toLocaleTitleCase,
  BACKEND_URL,
  API_URL,
  CAPTCHA_SITEKEY
}

export type { JSONArray, JSONObject, JSONValue }
export type { MenuItem, MenuSerialised, PageSerialised }
