import styles from './Notification.module.css'

interface Response {
  success: boolean
  message: string
}

export const Notification = ({ response }: { response: Response }) => {
  return (
    <div
      className={`${styles.notification} ${
        response.success ? styles.success : styles.failed
      }`}
    >
      {response.message}
    </div>
  )
}
