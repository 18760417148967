import styles from './Team.module.css'

type TeamProps = {
  children: React.ReactNode
}

function Team(props: TeamProps) {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        {props.children}
      </div>
    </div>
  )
}

export { Team }
