import { useState, useEffect } from 'react'


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return { width, height }
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const onResize = () => setWindowDimensions(getWindowDimensions())

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return windowDimensions
}

export { useWindowDimensions }
